import React from 'react'
import { Col, Row } from "reactstrap";
import Header from '../../../../components/Header2'
import BlogsCarousel from './BlogsCarousel';
import { Link } from 'react-router-dom';
function JungleRani() {
    const tags = ['Women Empowerment','Jungle Rani','Workshop']
  return (
    <div style={{overflow:'hidden'}}>
    <div className='home-landing-page'>
    <Header activeLink={'1'} background={true} modified={false}/>
    <Row className='home-page-title-row' style={{top:'95%'}}>
       
        {/* <p className='home-page-title'>JharFRA: Transforming Forest Governance in Jharkhand through Technology</p> */}
         <p className='home-page-title-desc'>Jamia Faculty interacting with Bonda Ghati girls during the workshop.</p>
      </Row>
      <img  src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jungleRani-landing-image.png'} className="background-video"/>
    <div>
    </div>
    </div>
    <div>
    <Row>
            <Col md="1">
            </Col>
            <Col md="8" className='jharfra-text'>
            <p className='jamia-title'>Jamia Conducted a one-Day Mobile Based Filming Workshop in Malkangiri, Odisha: Empowering Voices of Young Tribal Girls</p>
            <p className='jamia-desc'>{"A volunteer training program was conducted by the faculties of Jamia Millia Islamia University, focusing on mobile-based short filmmaking on 6 June 2003 in Telarai Panchyat, Kalimela. This seven-hour-long training aimed to equip volunteers with the skills and knowledge necessary to create impactful films that explore the multifaceted relationship between forest resources and tribal communities in Malkangiri. As a result of this training, the volunteers from forest communities, especially from Bonda Ghati, have taken the initiative to assert their individual voices, establish their own presence on social media platforms, and even organize a unique film festival. This festival will delve into the diverse dimensions of this interaction, shedding light on the myriad colors and complexities that exist in their daily lives. "}</p>
<div className='jamia-desc-main-impact-heading'>
    <p className='jamia-desc-main-impact-heading-text'>Impact of the Workshop among Young Tribals and Immediate Action Plan </p>
</div>
<p className='jharfra-desc'>{"All 30 volunteers, after the training, embarked on their journey to produce captivating films, and we have made noteworthy progress in streamlining the process."}</p>
<p className='jamia-desc-subtitle'>Structuring the Storyline: </p>
<p className='jharfra-desc'>{"One of the challenges faced by our volunteers pertained to formulating an engaging storyline. To address this, a dedicated team organized field training sessions aimed at assisting volunteers in structuring their stories, video shoots, and editing processes effectively. "}</p>
<p className='jamia-desc-subtitle'>Hand-Holding Training in Bonda Ghati:  </p>
<p className='jharfra-desc'>{"To provide comprehensive support, we devised a three-step hand-holding training program that focused on story development, shooting techniques, and editing skills. This training took place during a one-day camp in a village, where volunteers were encouraged to conduct interviews as a starting point for their films. Furthermore, they spent time in the village to capture natural shots and enhance the quality of their footage. Almost all the female volunteers have successfully completed this training. Subsequently, a full-day video editing training session is scheduled, after which they will publish their films on their own social platforms"}</p>
<p className='jamia-desc-subtitle'>Learning through Interviews and Voice-Overs: </p>
<p className='jharfra-desc'>{"Interviewing subjects and utilizing voice-overs to convey the story have emerged as the primary methods through which our volunteers are gaining expertise. Once they publish their first film, they gain the confidence to produce more captivating content from their respective locations. "}</p>
<p className='jamia-desc-subtitle'>Depository and Platforms: </p>
<p className='jharfra-desc'>{"We have designed a protocol for the deposition of raw and edited footage. IoFE field support Bhavani has been appointed to lead this initiative full-time, ensuring its expansion to more villages with young tribal girls as “Jungle Rani Reporters.” Their primary focus is to highlight issues related to forests and natural resources. These films will be published on two platforms: the Facebook page dedicated to the “Malkangiri forest economy” and the “Bado didi’s YouTube channel”. We are actively exploring additional platforms to amplify the impact of our initiative. "}</p>
<p className='jamia-desc-subtitle'>Own Film Festival:  </p>
<p className='jharfra-desc'>{"Recognizing the critical importance of providing forest communities with a platform to tell their narratives, the “Own Film Festival” is scheduled to take place on 21-22 July 2023. The festival will showcase the ten best films produced by our Jungle Rani Reporters, offering a platform to celebrate their talent and raise awareness about crucial environmental and tribal concerns. "}</p>
<p className='jharfra-desc'>Moving forward, the LoFE team is dedicated to focusing on three key aspects:  </p>
<li className='jharfra-desc'>Refresh Short Course/Training; We will be conducting a short refresher course to update our volunteers with the latest techniques and approaches in reporting and filming. </li>
<li className='jharfra-desc'>Digital Depository; We aim to establish a digital depository where all raw and edited footage can be safely stored and accessed for future reference and collaboration.  </li>
<li className='jharfra-desc'>Branding the “Jungle Rani Reporters”, We seek to position the Jungle Rani Reporters as an initiative led by young tribal girls dedicated to documenting and addressing issues related to forests and tribal communities. Through branding efforts, we aim to raise awareness and foster support for their valuable work.  </li>
<br></br>
<p className='jharfra-desc'>{"“Jungle Rani reporters” is an initiative of young tribal girls who will record and raise issues around the forests and tribals. "}</p>
<Link to="/our-work" style={{textDecoration:'none'}}>
<button style={{marginBottom:10}} className="blog-details-button blog-details-button-mob-1">Back to all work</button>
</Link>
            </Col>
            <Col md="3">
            {
            tags.map((tag)=>{
                return (
                    <button className='tag-button'>{tag}</button>
                )
            })
        }
        <br></br>
        <center>
        <Link to="/our-work" style={{textDecoration:'none'}}>
        <button style={{marginBottom:10,marginTop:10}} className="blog-details-button blog-details-button-mob">Back to all work</button>
            </Link>
        </center>
            </Col> 
            {/* <BlogsCarousel/> */}
        </Row>
        </div>
        </div>
  )
}

export default JungleRani