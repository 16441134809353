import React from 'react'
import bg from '../../assests/images/work_bg.png'
import Header from '../../components/Header2'
import { Row } from 'reactstrap'
import PublicationsList from './PublicationsList'

function Publications() {
    const items = [{},{},{},{}]
  return (
    <div>
    <div className='pubs-library-section'>
    <img src={bg} className="background-video-1"/>
    <Header background={false} modified={true}/>
    <div className='pubs-library-container'>
        <Row>
            {
                items.map((item)=>{
                    return(
            <div className='pubs-header-card'>
                <p className='pubs-card-date'> 10 May 24’</p>
                <p className='pubs-card-title'>
                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
                </p>
                <p className='pubs-card-desc'>
                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet  ipsum quia dolor sit amet  ipsum quia dolor sit amet. sit amet  ipsum quia dolor sit amet. ipsum quia dolor sit amet  ipsum quia dolor sit amet. sit amet  ipsum quia dolor sit amet
                </p>
                <p className='pubs-card-source'>
                Source: American economic review
                </p>
                <p className='pubs-card-date1'> 10 May 24’</p>
            </div>
                    )
                })
            }
            
        </Row>
        </div>
        </div>
        <PublicationsList/>
        </div>
  )
}

export default Publications