import React from 'react'
import Header from '../../../components/Header2'
import { Col, Row } from 'reactstrap'
import MyCarousel from '../../home/Carousel'

function SMI() {
  return (
    <div style={{background: 'var(--Background, #F9F2EF)'}}>
        <div className='smi-header'>
        <Header activeLink={'2'} background={false} modified={true}/>
        </div>
        <div>
            <Row style={{paddingLeft:5}}>
                <Col md="1">
                </Col>
                <Col md="10" className='smi-title-col'>
                <p className='smi-title-text'>BIPP-ISB Formalises the Relationship with Srishti Manipal Institute of Art, Design and Technology</p>
            <p className='smi-desc-text'>The three-year MoU will focus on fostering academic and knowledge collaboration between SMI and BIPP-ISB for the effective implementation of a large-scale landscape-level Initiative on the Forest Economy (IoFE).</p>
                </Col>
            <Col md="1">
                </Col>
            </Row>
        </div>
        <div>
            <div>
                <Row   className='mt-3'>
                    <Col md="12">
                    <div className='smi-about-the-project-div'>
                        <p className='smi-about-the-project-div-text'>About the Project</p>
                    </div>
                    </Col>
                </Row>
            </div>
        </div>
        <div>
            <Row style={{paddingLeft:5}} className='mt-2'>
                <Col md="1">
                </Col>
                <Col md="10">
                <p className='jharfra-desc mt-3'>On 22 September 2023, Bharti Institute of Public Policy, ISB and Srishti Manipal Institute of Art, Design and Technology signed a Memorandum of Understanding (MoU) to build together a scalable and sustainable forest economy and livelihood anchored around Community Forest Resource Rights (CFR) and Women-led Enterprises.
                The major objective of the partnership is to facilitate the exchange of information on research and educational programs, teaching materials, and literature relevant to their educational and research activities. It also involves jointly organizing short-term education programs, seminars, conferences, and workshops on mutual interests, with invitations extended to each other’s faculty for participation.</p>
                <p className='jamia-desc-subtitle mt-3'>Additionally, the MoU will facilitate-</p>
                <li className='jharfra-desc'>Joint Summer Internship for the students of B. Des., M. Des, BFA, and MFA programs at SMI</li>
                <li className='jharfra-desc'> Participation in multi-institutional collaboration to design and develop a multi-media case study platform. The proposed platform acts as a new pedagogical tool for imparting education in humanities, social sciences, development studies, management education, and related fields. </li>
                <li className='jharfra-desc'>Final semester project dissertation joint fellowship for M. Des. and MFA students.</li>
                <li className='jharfra-desc'>Conceptualization and co-production of documentaries focused on forest governance, economy, livelihood, agriculture-nutrition convergence, etc.</li>
                <p className='jharfra-desc mt-5'>The MoU highlights the vital role of youth participation in research, as it paves the way for innovative approaches to a sustainable forest economy, reinforcing the importance of fresh perspectives and dynamic solutions in shaping a brighter future for our ecosystems and communities.</p>
                </Col>
                <Col md="1">
                </Col>
                <center><button className='blog-details-button'>Back to Library</button></center>
            </Row>
        </div>
        <div>
            <Row className='mt-5' style={{paddingLeft:5}}>
                <Col md="1">
                </Col>
                <Col md="10">
                <MyCarousel/>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default SMI