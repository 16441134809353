import React, { useLayoutEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import bgVideo from '../../assests/images/main_bg.mp4'
import Example from '../../components/Header2'
import Header from '../../components/Header2'
import { Link } from 'react-router-dom'

function Home() {
  const [size,setSize] = useState([]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)
  return (
    <div className='home-landing-page' style={{overflow:'none'}}>
        <Header activeLink={'2'} modified={false}/>
        {
          size.length>0 && size[0]<768?
          <Row className='library-page-title-row-mob'>
           

          <p className='library-page-title-1-mob'>
          Shaping Tomorrow's Forests: Transformative Initiatives for a Sustainable Future
          </p>
       
          {/* <p className='library-page-title-3-mob'>
          Source: American economic review
          </p >
          <p className='library-page-title-3-mob'>
          23 May 25’
          </p> */}
          <div className='library-page-button-div-mob'>
            <Link to="/library/publications">
          {/* <button className='library-page-button-mob'>Our publications</button> */}
            </Link>
          </div>
            
           
        </Row>
          :
          <Row className='library-page-title-row'>
           

            <p className='library-page-title-1'>
            Shaping Tomorrow's Forests: Transformative 
            </p>
            <p className='library-page-title-2'>Initiatives for a Sustainable Future
            </p>
            {/* <p className='library-page-title-3'>
            Source: American economic review
            </p >
            <p className='library-page-title-3'>
            23 May 25’
            </p> */}
            <div className='library-page-button-div'>
              <Link to="/library/publications">
            {/* <button className='library-page-button'>Our publications</button> */}
              </Link>
            </div>
              
             
          </Row>
        }
   <video className="background-video" autoPlay={true} loop muted>
        <source src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/main_bg.mp4'} type="video/mp4" />
      </video> 
        <div>
        </div>
    </div>
  )
}

export default Home