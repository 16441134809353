import React from 'react'
import Header from '../../../components/Header2'
import { Col, Row, UncontrolledCollapse } from 'reactstrap'
import Events from '../../home/Events'

function Ncount_Training_Mohali() {
  return (
    <div>
               <div className='home-landing-page'>
    <Header activeLink={'4'} background={true} modified={false}/>
    <Row className='home-page-title-row' style={{top:'85%'}}>
       
        <p className='home-page-title'>Collaborative Initiative on the Scientific Forest Management in Himachal Pradesh</p>
        {/* <p className='home-page-title' style={{marginBottom:0,fontSize:30,fontWeight:700}}>IOFE ‘23</p> */}
         <p className='home-page-title-desc'>
         Abhijeet, lead, Tech & Research Partnership, BIPP-ISB presenting the results and technical insights of the nCount initiative during the review meeting at ISB Mohali Campus.
         </p>
      </Row>
      <img  src={"https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ncount-training-mohali.png"} className="background-video"/>
    </div>
    <div style={{background: 'var(--Background, #F9F2EF)'}}>
    <div className='knowledge-sharing-session'>
    <div className='mt-3'>
    <Row  id="toggler1" style={{cursor:'pointer'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div'>
                        <p className='smi-about-the-project-div-text'>About the Project</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler1">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                On January 15, 2024, the Himachal Pradesh Forest Department and the Bharti Institute of Public Policy (BIPP) at the Indian School of Business (ISB) launched a collaborative project to inventory major seasonal forest products in the state. This project aims to estimate the occurrence and distribution of these products and project potential revenue for the state government through sustainable management and regulated trade.
<br></br>
<br></br>
Phase one of the nCount training was implemented across six divisions in Himachal Pradesh: Ani, Dehra, Nachan, Nurpur, Palampur, and Paonta. Over two months, the forest department successfully collected approximately 40,000 data points on various forest species.
<br></br>
<br></br>
On June 9, 2024, a review meeting was held at the ISB Mohali campus to discuss the on-ground experiences of forest officials, address their queries, and incorporate their insights for the planning of Phase II. This meeting provided a platform to refine the nCount technology based on practical feedback, ensuring its effective implementation for sustainable forest management.
<br></br>
<br></br>
Prof. Ashwini Chhatre, Executive Director of BIPP, ISB, welcomed the Himachal Pradesh Forest Department (HPFD) officials and initiated the meeting with introductions. He outlined the agenda, focusing on sharing field experiences and lessons learned from adopting the nCount technology. Prof. Chhatre emphasized that insights from nCount could refine forest economy initiatives and help propose nCount as a modern technology for better forest management to other state governments.
<br></br>
<br></br>
Dr. Pushpendra Rana, Additional Principal Chief Conservator of Forests (APCCF), discussed the utility and benefits of the nCount platform for Himachal Pradesh Forest Department and local communities, highlighting its role in sustainable forest resource management and economic linkages for community livelihoods. He explained that integrating AI and machine learning would enable the forest department to create and maintain a verified inventory of Seasonal Forest Products (SFPs) and track annual changes, assisting in preparing working plans and resource inventories.
      <br>
      </br>  
<br></br>
      During the review meeting, foresters shared their experiences and challenges encountered while using the nCount and ODK technologies for forest inventory management. The discussions highlighted several critical issues related to data collection, species selection, and the capacity and training needs of forest guards      
               </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </UncontrolledCollapse>
        <div className='mt-3'>
    <Row  id="toggler2" style={{cursor:'pointer'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div' style={{background: '#D9AC98'}}>
                        <p className='smi-about-the-project-div-text'>Data Collection</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler2">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                One major challenge was the high number of data points required for shrubs and herbs, which proved difficult to achieve in areas with limited forest guard resources. It was clarified that these statistical requirements are essential for the model to produce accurate rangelevel maps. Additionally, forest guards reported difficulties in capturing photos of tall trees and trees surrounded by dense vegetation. The training protocols specified that photos should be taken without zooming, which posed a challenge in some situations. There were also issues with achieving the required 5meter accuracy for data capture in dense forests, leading to a proposal to increase the allowable accuracy threshold to 10 meters.
                </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </UncontrolledCollapse>
    
        <div className='mt-3'>
    <Row  id="toggler3" style={{cursor:'pointer'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div' style={{background: 'var(--Light, #E68662)'}}>
                        <p className='smi-about-the-project-div-text'>Species Selection</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler3">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The discussion revealed the need to include a wider variety of bamboo species and other regionspecific flora. For example, the Sal tree, abundant in certain forest divisions, was not adequately represented in the nCount forms. Suggestions were made to add species such as Shisham, Dhau, Curry Patta, Garna, Rohini, Ban Oak, and Deodar. It was agreed that Cedrus deodara and Quercus leucotrichophora would be added to the ODK platform to better reflect the biodiversity of the region.
               </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </UncontrolledCollapse>
    
        <div className='mt-3'>
    <Row  id="toggler4" style={{cursor:'pointer'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div' style={{background: 'rgba(123, 60, 46, 0.40)'}}>
                        <p className='smi-about-the-project-div-text'>Other observations </p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler4">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                Forest guards were noted to have limited knowledge of medicinal plants, herbs, and shrubs, indicating a need for additional training workshops led by experts. It was suggested that traditional area sampling techniques be employed instead of tree species sampling. However, it was explained that the AI-based model addresses the limitations of area sampling by using satellite imagery to generate prediction models.
                <br></br>
                <br></br>
                A new monitoring dashboard is being developed to help DFOs track progress and plan better. Additionally, the lack of access to ODK for iPhone users was highlighted, with assurances that an iPhone-compatible version would be available soon. Concerns were also raised about capturing data for herbs and shrubs, with questions about the feasibility of using satellite imagery for this purpose. Challenges in creating small polygons of 5 meters were also discussed, with the need for larger polygons being recognized.

                </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </UncontrolledCollapse>
    
        <div className='mt-3'>
    <Row  id="toggler5" style={{cursor:'pointer',background: 'rgba(218, 134, 97, 0.50)'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div' style={{background: 'rgba(218, 134, 97, 0.50)'}}>
                        <p className='smi-about-the-project-div-text'>Technical Analysis of Phase-1 Presentation</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler5">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The results of Phase I were presented, showing around 40,000 data points collected. Species distribution maps were displayed, and the process behind their creation was explained. The presentation highlighted the classification of five species: Taxus baccata, Sal, Rhododendron, Khair, and Wild Mango. It detailed how satellite imagery, AI models, and other data sources were used to create accurate species distribution maps.
<br></br>
It was clarified that the AI model is designed to address the inherent limitations of area sampling methods, using a combination of satellite imagery, spectral data, and other parameters to build a comprehensive prediction model. The issue of visualizing species distribution at different scales was also addressed, emphasizing the need for more data points to enhance accuracy.
Validation efforts were discussed, including techniques to ensure data accuracy and reliability. For instance, the use of Google Earth snapshots and point cloud simulations demonstrated high accuracy levels in species identification and geometry reconstruction. The meeting concluded with a focus on the need for at least 5000 data points per species to ensure robust validation. Once this threshold is met, the actual model will be used to generate predictions, which will undergo further validation.
                </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </UncontrolledCollapse>

        <div className='mt-3'>
    <Row  id="toggler6" style={{cursor:'pointer',background: '#D9AC98'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div' style={{background: '#D9AC98'}}>
                        <p className='smi-about-the-project-div-text'>Way Forward: Phase-II of nCount Implementation</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler6">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The next phase of the nCount initiative will focus on strategic species selection and extensive data collection to enhance forest management. Prof. Ashwini Chhatre emphasized the importance of choosing species with high market potential, such as Camellia, which is expected to gain value as a nature-based substitute in the chemical industry. This strategic preparation will attract industry partners interested in sustainably harvested forest products, benefiting local communities.
<br></br>
<br></br>
<span style={{fontWeight:600}}>
The primary goals for Phase II are-
</span>
<br></br>
<br></br>
1.&nbsp; Data Collection: Aim to collect at least 5000 data points per species to ensure comprehensive coverage and accuracy. Expand the species inventory by adding at least 40 new high-altitude species, such as Quercus incana, Deodar, Rohini, Seabuck Thorn, and Kadu. Data collection for these species will begin in July and last for two months, targeting locations like Pangi, Rohru, Spiti, Nachan, and Nurpur.
<br></br>
<br></br>
2. &nbsp;Drone Mapping: Initiate drone mapping from June 25, with tech team members and foresters visiting high-altitude regions. The drones, equipped with 4872-megapixel cameras and three sensors, will accurately georeference structural data to gather points for herbs. Collaborate with IIT Delhi to develop robust autonomous drone models, addressing challenges in automated flight. The drone mapping will be conducted in three phases: initial calibration with standard drones, followed by detailed mapping in specific forest areas.
<br></br>
<br></br>
During the discussions, Pushendra Rana highlighted that drone technology would also aid in identifying forest fire locations and water storage areas, enhancing resource inventory mapping and rejuvenation efforts.
 Immediate Next Steps
<br></br>
<br></br>
Commencement: Phase 2 activities will begin in July, with drone mapping starting on June 25.
<br></br>

Species Addition: Formalize the inclusion of species like Deodar, Ban Oak, and various bamboo species (Bambusa Bamboo and Dandrocalamus strictus).
<br></br>
Training: Organize expert-led training sessions for foresters on the traditional knowledge of Seasonal Forest Products (SFPs).
<br></br>
Technology Updates: Launch Version 2 of the nCount platform, compatible with Apple devices, incorporating the collected specieswise data.
<br></br>
Capacity Building: Conduct capacity building and training programs for foresters on nCount technology and drone mapping techniques.
<br></br>
Data Collection Protocol: Foresters will capture polygons within a 10-meter range, take non-zoomed leaf pictures, and ensure accurate data collection for bamboo species.
<br></br>
Future Meetings: Schedule the next review meeting in August, involving current DFOs and those from high-altitude regions.
<br></br>
Validation: Ensure data validation through ground truthing to maintain data accuracy and reliability.
<br></br>
Permissions: Obtain necessary permissions from the forest department to fly drones in HP forests.
<br></br>
These steps aim to refine and expand the nCount initiative, leveraging advanced technology for sustainable and effective forest management in Himachal Pradesh.
               </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </UncontrolledCollapse>

        </div>
        </div>
        <Events/>
    </div>
  )
}

export default Ncount_Training_Mohali