import React from 'react'
import { Col, Row } from 'reactstrap'
import bgVideo from '../../assests/images/main_bg.mp4'
import Example from '../../components/Header2'
import Header from '../../components/Header2'
import bgLayer from '../../assests/images/bg-layer.png';
import { Link } from 'react-router-dom'
 
function Home() {
  return (
    <div className='home-landing-page' style={{zIndex:2}}>
        <Header activeLink={'0'} modified={false}/>
          <Row className='home-page-title-row'>
           
            <p className='home-page-title'>Building Sustainable and Robust Forest Futures for People, Planet and Profits</p>
             <Link to="/about-us"> <center><button className='home-page-button'>Know More About Us</button></center></Link>
              
             
          </Row>
          <img src={bgLayer} style={{zIndex:1}} className="background-video"/>
   <video className="background-video" autoPlay={true} loop muted>
        <source src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/main_bg.mp4'} type="video/mp4" />
      </video> 
        <div>
        </div>
    </div>
  )
}

export default Home