import React from 'react'
import Header from '../../../components/Header2'
import { Col, Row, UncontrolledCollapse } from 'reactstrap'
import Events from '../../home/Events'
function ForestBioenergy() {
    return (
        <div>
                   <div className='home-landing-page'>
        <Header activeLink={'4'} background={true} modified={false}/>
        <Row className='home-page-title-row' style={{top:'85%'}}>
           
            <p className='home-page-title'>The Forests’ Bioenergy Opportunity: Investments Powering Sustainability</p>
            {/* <p className='home-page-title' style={{marginBottom:0,fontSize:30,fontWeight:700}}>IOFE ‘23</p> */}
             {/* <p className='home-page-title-desc'>
             Abhijeet, lead, Tech & Research Partnership, BIPP-ISB presenting the results and technical insights of the nCount initiative during the review meeting at ISB Mohali Campus.
             </p> */}
          </Row>
          <img  src={"https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/events/roundTables/Roundtable_Bioenergy+1.png"} className="background-video"/>
        </div>
        <div style={{background: 'var(--Background, #F9F2EF)'}}>
        <div className='knowledge-sharing-session'>
        <div className='mt-3'>
        <Row  id="toggler1" style={{cursor:'pointer'}}>
                        <Col md="12">
                        <div className='smi-about-the-project-div'>
                            <p className='smi-about-the-project-div-text'>About the Event</p>
                        </div>
                        </Col>
                    </Row>
        </div>

        <div className='pt-3'>
                <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                    <Col md="1">
                    {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                    </Col>
                    <Col md="10">
                    <p className='jharfra-desc'>
                    The Investment Roundtable on “The Forests’ Bioenergy Opportunity,” held on February 15th, 2024, at the Indian School of Business Hyderabad, brought together a diverse group of stakeholders to discuss the untapped potential of forest resources in bioenergy production. Organized by GIZ and the Initiative on the Forest Economy at Bharti Institute of Public Policy ISB, this event aimed at exploring the roles of investment and innovation in harnessing forest resources for sustainable energy solutions. The discussions emphasized sustainable utilization of resources, innovative financial strategies, and the importance of collaborative efforts between communities, investors, and the government to overcome challenges and pave the way for a sustainable bioenergy sector. 
 <br></br><br></br>
The roundtable concluded with a strategic vision for the future, focusing on the creation of a comprehensive document to serve as a roadmap for investments in the forest bioenergy sector. This document highlights opportunities, addresses constraints, and explores financing options, guiding stakeholders toward sustainable development and growth in this emerging field. The event underscored the importance of continuous engagement, capacity building, and innovative solutions to realize the full potential of forests in bioenergy production, marking a significant step forward in the journey towards a sustainable energy future. 
                    </p>
                    </Col>
                    <Col md="1">
                    </Col>
                </Row>
            </div>
            </div>
            </div>
            <Events/>
        </div>
      )
    }
export default ForestBioenergy