import React from 'react'
import Header from '../../../components/Header2'
import { Col, Row } from 'reactstrap'
import MyCarousel from '../../home/Carousel'

function BiofuelCircle() {
    const tags = ['Objective and Collaboration  ','Scope of work','Roles and Responsibilities']
    return (
      <div>
          <div className='home-landing-page'>
      <Header activeLink={'2'} background={true} modified={false}/>
      <Row className='home-page-title-row' style={{top:'90%'}}>
         
          <p className='home-page-title'>BiofuelCircle and BIPP-ISB Collaborate to Strengthen Forest-Based Bioenergy Value Chains</p>
           {/* <p className='home-page-title-desc'>Chief Minister Hemant Soren during the launch of "Abua Bir Abua Dishom Abhiyan" (My Jungle, My Country) in Ranchi, Jharkhand.</p> */}
        </Row>
        <img  src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/biofuelcircle.png'} className="background-video"/>
      </div>
      <div style={{background: 'var(--Background, #F9F2EF)'}}>
      <div>
      <Row>
                      <Col md="12">
                      <div className='smi-about-the-project-div'>
                          <p className='smi-about-the-project-div-text'>About the Project</p>
                      </div>
                      </Col>
                  </Row>
      </div>
      <div>
              <Row style={{paddingLeft:5}} className='mt-2'>
                  <Col md="1">
                  </Col>
                  <Col md="10">
                  <p className='jharfra-desc mt-3'>Bharti Institute of Public Policy, ISB, and BiofuelCircle Private Limited (BFC) have signed a three-year Memorandum of Understanding (MoU) on June 9, 2023, to foster collaboration in strengthening the forest economy by creating sustainable and efficient bioenergy value chains. This partnership aims to utilize forests as sustainable sources of raw materials for the bioenergy sector and empower forest-based community-owned enterprises with secure tenure.
                  BiofuelCircle Private Limited is a pioneering company in the bioenergy sector, committed to promoting sustainable and environmentally friendly sources of energy. BFC focuses on creating value chains that empower communities and protect the environment. </p>
                  {
              tags.map((tag)=>{
                  return (
                      <button className='tag-button'>{tag}</button>
                  )
              })
      }
              </Col>
                  <Col md="1">
                  </Col>
                  {/* <center><button className='blog-details-button'>Back to Library</button></center> */}
              </Row>
          </div>
          <div className='mt-3'>
              <Row className='smi-about-the-project-div hp_colab_div'>
                  <Col md="1">
                  <p className='hp_colab_text'>Objective and Collaboration</p>
                  </Col>
                  <Col md="10">
                  <p className='jharfra-desc hp_colab_left_border'>
                  The primary objective of this collaboration is to enable the creation of large-scale, efficient, and sustainable value chains in the bioenergy sector, with forests serving as a secure and sustainable source of raw materials. Forest-based community-owned enterprises, supported by secure tenure, will form the cornerstone of these bioenergy value chains.
                  </p>
                  </Col>
                  <Col md="1">
                  </Col>
              </Row>
          </div>
          <div className='mt-3'>
              <Row className='smi-about-the-project-div hp_colab_div' style={{background: 'var(--Background, #F9F2EF)'}}>
                  <Col md="1">
                  <p className='hp_colab_text'>Scope of Work </p>
                  </Col>
                  <Col md="10">
                  <p className='jharfra-desc hp_colab_left_border'>
                BIPP-ISB and BFC will collaborate on the following key areas: (a) developing end-to-end bioenergy value chains with a focus on forest-based communities with secure tenure as the primary suppliers of raw materials, (b) establishing transparent, sustainable, and scalable forest-based value chains for bioenergy.
<br></br>
The initial focus of the collaboration will be on building bioenergy value chains using pine needles sourced from Himachal Pradesh, specifically the Hamirpur district and Bhattiyat cluster of Chamba district, in the first year. Both parties will explore opportunities to expand the scope and geography of their collaboration in the future.
                  </p>
                  </Col>
                  <Col md="1">
                  </Col>
              </Row>
          </div>
          <div className='mt-3'>
              <Row className='smi-about-the-project-div hp_colab_div'>
                  <Col md="1">
                  <p className='hp_colab_text'>Roles and Responsibilities</p>
                  </Col>
                  <Col md="10">
                  <p className='jharfra-desc hp_colab_left_border'>
                  The roles and responsibilities of both parties are clearly defined to ensure the success of this partnership. The initiative on the Forest Economy team will work closely with governments and communities to operationalize secure tenure, identify raw material sources, and mobilize forest-based communities. BFC will focus on mapping value chains, facilitating purchase transactions, and creating a competitive marketplace for bioenergy sources from community-owned enterprises.
<br></br>
BiofuelCircle, utilizing its digital platform, will facilitate transparently mapping the value chains, streamlining the logistics of sourcing, and enabling large-scale transactions between community-owned enterprises and buyers. Its primary function is to connect local communities with various industries that currently purchase their biofuels through the BiofuelCircle platform.
<br></br>
This MoU signifies a significant step towards sustainable forest-based bioenergy value chains and highlights the commitment of both organizations to make a meaningful impact on the Indian economy and environment.  
                  </p>
                  
                  </Col>
                  <Col md="1">
                  </Col>
              </Row>
              
          </div>
          <center><button className='blog-details-button mt-3'>Back to Library</button></center>
          <div>
              <Row className='mt-5' style={{paddingLeft:5}}>
                  <Col md="1">
                  </Col>
                  <Col md="10">
                  <MyCarousel/>
                  </Col>
                  <Col md="1">
                  </Col>
              </Row>
          </div>
      </div>
      </div>
    )
  }
export default BiofuelCircle