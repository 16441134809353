import React from 'react'
import { Col, Row } from 'reactstrap'

function Workshops() {
  return (
    <div style={{background:'#D9AC98'}}>
         <Row className='event-workshop-row'>
          <p className='event-workshop-title'>Workshops and Events</p>
        </Row>
        <Row style={{justifyContent:'center'}}>
            <Col xs="12" md="8">
            <Row style={{justifyContent:'center'}} className='mt-4 mb-4'>
                <Col xs="6" md="4">
        <Row style={{justifyContent:'center'}} className='event-workshop-row-1 mt-2'>
            <div className='event-workshop-row-1-div'>
                <p className='event-workshop-row-1-div-text'>The JharFRA App Training Initiative under the Abua Bir Abua Dishom Abhiyan in Jharkhand</p>
            </div>
        </Row>
                </Col>
                <Col xs="6" md="4">
        <Row style={{justifyContent:'center'}} className='event-workshop-row-1 mt-2'>
            <div className='event-workshop-row-1-div'>
                <p className='event-workshop-row-1-div-text' style={{paddingTop:22,paddingBottom:22,paddingLeft:0,paddingRight:0,fontSize:11}}>Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring</p>
            </div>
        </Row>
                </Col>
                <Col xs="6" md="4">
        <Row style={{justifyContent:'center'}} className='event-workshop-row-1 mt-2'>
            <div className='event-workshop-row-1-div'>
                <p className='event-workshop-row-1-div-text'>The JharFRA App Training Initiative under the Abua Bir Abua Dishom Abhiyan in Jharkhand</p>
            </div>
        </Row>
                </Col>
                <Col xs="6" md="4" className='mt-2'>
        <Row style={{justifyContent:'center'}} className='event-workshop-row-1'>
            <div className='event-workshop-row-1-div'>
                <p className='event-workshop-row-1-div-text' style={{paddingTop:47,paddingBottom:47,paddingLeft:0,paddingRight:0}}>Collaborative Initiative on the Scientific Forest Management in Himachal Pradesh</p>
            </div>
        </Row>
                </Col>
                <Col xs="6" md="4" className='mt-2'>
        <Row style={{justifyContent:'center'}} className='event-workshop-row-1'>
            <div className='event-workshop-row-1-div'>
                <p className='event-workshop-row-1-div-text' style={{paddingTop:20,paddingBottom:20,paddingLeft:0,paddingRight:0,fontSize:10}}>ISB-TCS Conducted a Field Carbon Measurement Workshop in Simdega, Jharkhand: Securing Forest Resources through Citizen Science </p>
            </div>
        </Row>
                </Col>
                <Col xs="6" md="4">
                </Col>
               
            </Row>
            </Col>
        </Row>
    </div>
  )
}

export default Workshops