import React from 'react'
import Header from '../../../../components/Header2'
import { Row } from 'reactstrap'
import demoPubs from '../../../../assests/images/blog-demo.png'
import BlogDetails from './BlogDetails'


function Home() {
  return (
    <div className='home-landing-page'>
    <Header background={false} modified={false}/>
      <Row className='home-page-title-row'>
       
        <p className='home-page-title'>Shaping Tomorrow's Forests: Transformative Initiatives for a Sustainable Future</p>
         
      </Row>
      <img src={demoPubs} className="background-video"/>
    <div>
    </div>
</div>
  )
}

export default Home