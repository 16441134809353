import React, { useLayoutEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import fieldDemo from "../../../../assests/images/field-demo.png";
import { Carousel } from "bootstrap";
import MyCarousel from "../../../home/Carousel";
import { useNavigate } from "react-router-dom";

function BlogDetails() {
  const [activeYear, setActiveYear] = useState(0);
  const changeActiveYear = (id) => {
    setActiveYear(id);
  };
  const [size,setSize] = useState([]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)
  return (
    <div style={{ overflow: "hidden", background: "#F9F2EF" }}>
      <Row className="about-the-project-text-row-1" >
        <Row className="about-the-project-text-row-2">
        <div style={{ width: "10%" }}></div>
        <div className={size.length>0 && size[0]<768 ? "about-the-project-text-mob":"about-the-project-text"}>
          <p className="blog-title-text">About the Project</p>
        </div>

        <div className={size.length>0 && size[0]<768 ?"blog-desc-text-division-mob":"blog-desc-text-division"}>
          <p className="blog-desc-text">
            ed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. ed ut perspiciatis unde omnis iste natus
            error.. ed ut perspiciatis unde omnis iste natus error sit
            voluptatem accusantium doloremque laudantium, totam rem aperiam,
            eaque ipsa quae ab illo inventore veritatis et quasi architecto
            beatae vitae dicta sunt explicabo. ed ut perspiciatis unde omnis
            iste natus error.. ed ut perspiciatis unde omnis iste natus error
            sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
            eaque ipsa quae ab illo inventore veritatis et quasi architecto
            beatae vitae dicta sunt explicabo. ed ut perspiciatis unde omnis
            iste natus error.
          </p>
        </div>
        </Row>
      </Row>
      <Row className="blog-details-by-year">
        <div
          onClick={() => setActiveYear(0)}
          className={
            activeYear == 0 
              ? (size.length>0 && size[0]<768 ? "blog-details-2019-mob" :"blog-details-2019" )
              : size.length>0 && size[0]<768 ?"blog-details-2019-not-active-mob" : "blog-details-2019-not-active"
          }
        >
          <p>2019</p>
        </div>
        <div
          onClick={() => setActiveYear(1)}
          className={
            activeYear == 1
              ? (size.length>0 && size[0]<768 ? "blog-details-2021-mob":"blog-details-2021")
              : (size.length>0 && size[0]<768 ?"blog-details-2021-not-active-mob": "blog-details-2021-not-active")
          }
        >
          <p>2021</p>
        </div>
        <div
          onClick={() => setActiveYear(2)}
          className={
            activeYear == 2
              ? (size.length>0 && size[0]<768 ?"blog-details-2023-mob" : "blog-details-2023")
              : (size.length>0 && size[0]<768 ? "blog-details-2023-not-active-mob" :"blog-details-2023-not-active")
          }
        >
          <p>2023</p>
        </div>
      </Row>
      <Row className="blog-field-division-row">
        <div lassName="blog-field-division_blank"></div>
        <div className="blog-field-division">
          <img className="blog-field-img" src={fieldDemo} />
          <p className="blog-field-img-desc">
            ed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. ed ut perspiciatis unde omnis iste natus
            error. beatae vitae dicta sunt explicabo. ed ut perspiciatis unde
            omnis iste natus error.
          </p>
          <button className="blog-details-button">Back to all projects</button>
        </div>
      </Row>
      <MyCarousel />
    </div>
  );
}

export default BlogDetails;
