import React from 'react'
import Home from './Home'
import OurStories from './OurStories'
import Videos from './Videos'
import Updates from './Updates'
import PubsList from './PubsList'

function Library() {
  return (
    <div style={{width:'100vw',overflowX:'hidden'}}>
    <Home/>
    {/* <OurStories/> */}
    <PubsList/>
    {/* <Updates/> */}
    </div>
  )
}

export default Library