import React from 'react'
import Header from '../../../components/Header2'
import { Col, Row, UncontrolledCollapse } from 'reactstrap'
import Events from '../../home/Events'

function ILE23() {
  return (
    <div>
         <div className='home-landing-page'>
    <Header activeLink={'4'} background={true} modified={false}/>
    <Row className='home-page-title-row' style={{top:'85%'}}>
       
        <p className='home-page-title'>International Learning Exchange 2023: Navigating the Terrain of Radical Forest Futures</p>
        <p className='home-page-title' style={{marginBottom:0,fontSize:30,fontWeight:700}}>IOFE ‘23</p>
         <p className='home-page-title-desc'>Delegates from diverse global communities unite in a vibrant celebration of culture, hope, and solidarity at the International Learning Exchange 2023.
         </p>
      </Row>
      <img  src={"https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ILE'23.png"} className="background-video"/>
    </div>
    <div style={{background: 'var(--Background, #F9F2EF)'}}>
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p>
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                To empower voices from diverse communities and build collaborative relationships, the Bharti School of Public Policy at ISB initiated the inaugural ‘International Learning Exchange 2023’ (ILE 2023). This platform was co-created with the Policy and Development Advisory Group, Society for Rural, Urban and Tribal Initiative, Vasundhara, and the AJK Mass Communication Centre at Jamia Millia Islamia, aiming to create a dynamic multi-stakeholder platform. The International Learning Exchange, under the theme “Radical Forest Futures,” was an epochal convergence held from November 2nd to 4th, 2023, at Delhi University’s Multipurpose Hall Sports Complex.
<br></br>
<br></br>
This gathering was a crucible of thought and action, addressing the urgent and complex issues of forest conservation and climate change mitigation through the lens of the Forest Rights Act of 2006. The event stood out for its interdisciplinary approach, attracting a mosaic of participants — community leaders, environmental activists, policymakers, journalists, academicians, and students — creating a melting pot of perspectives and expertise.
<br></br>
<br></br>
This conclave was a harmonious blend of academic discourse, cultural celebration, and community engagement. It was an embodiment of the idea that understanding and addressing environmental issues require a multifaceted approach, encompassing legal, social, cultural, and environmental dimensions. The event commenced with an informal setting, promoting equality and community harmony. Professor Ashwini Chhatre, Executive Director of BIPP, Indian School of Business opened the event, setting a tone of collaboration and shared learning. A pictorial introduction to Delhi highlighted the city’s historical and modern aspects, showcasing its rich cultural and environmental heritage. 
                </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        <div className='knowledge-sharing-session'>
    <div className='mt-3'>
    <Row  id="toggler1" style={{cursor:'pointer'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div'>
                        <p className='smi-about-the-project-div-text'>Knowledge Sharing Sessions</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler1">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The Knowledge Sharing Sessions were the backbone of the event, facilitating deep dives into the intricacies and impacts of the Forest Rights Act 2006. These sessions illuminated the varying trajectories of the Act’s implementation across Indian states, shedding light on both its triumphs and tribulations. Key discussions revolved around the empowerment that community forest titles bestowed upon local communities, particularly in terms of managing and commercially benefiting from forest resources like bamboo and Tendu leaves.
<br></br>
<br></br>
The sessions also shone a spotlight on women’s leadership within the Forest Rights Act framework. This was a critical discourse highlighting how women, deeply versed in local flora and traditional practices, are indispensable to sustainable forest management. The narrative extended to explore the challenges and achievements in Community Forest Rights, addressing the protection of forest dwellers’ rights and the role of community engagement in forest resource management. The discussions were not confined to Indian perspectives but also embraced international experiences, sharing insights from countries like Tanzania, Kenya, Indonesia, Malaysia, Cameroon, and Brazil.
             </p>
             <div className='mt-2'>
                <img className='toggler-img' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ile_img1.png'} />
            </div>
                </Col>
                <Col md="1">
                </Col>
            </Row>
            {/* <div>
                <img style={{width:'100%',height:'75vh',objectFit:'cover'}} src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ile_img1.png'} />
            </div> */}
            <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                List of Knowledge Session
<br></br>
Session 1: Transition from Titles to Ownership         
  <br></br>
Session 2: Role of Women Leadership Within the FRA Purview      
  <br></br>
Session 3: Community Forest Rights within FRA: Challenges and Achievements
<br></br>
Session 4: Chhattisgarh and the challenges in the context of CFR and FRA  
    <br></br>
Session 5: Social Cohesion: An Inclusive Forest-Dependent Community 
    <br></br>
Session 6: Exploring International Perspectives on Community Forest Relationships  
                 </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </div>
        </UncontrolledCollapse>
    
        </div>
        <div className='knowledge-sharing-session'>
    <div className='mt-3'>
    <Row  id="toggler2" style={{cursor:'pointer'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div' style={{background: '#B08A82'}}>
                        <p className='smi-about-the-project-div-text'>Phoenix Talks</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler2">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
             <div className='mt-2'>
                <img className='toggler-img' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ile_img4.png'} />
            </div>
                </Col>
                <Col md="1">
                </Col>
            </Row>
            {/* <div>
                <img style={{width:'100%',height:'75vh',objectFit:'cover'}} src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ile_img1.png'} />
            </div> */}
            <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The Phoenix Talks were a tapestry of human spirit and resilience, narrating stories of individuals who transcended societal and environmental challenges to emerge as catalysts of change. These sessions served as a powerful testimony to the human capacity for overcoming adversity and contributing to societal upliftment. Speakers from diverse backgrounds shared their experiences — from battling societal norms and environmental challenges to advocating for community rights and women’s empowerment. These narratives not only inspired but also offered practical insights into grassroots activism and community-led conservation efforts.
<br></br>
<br></br>
<span style={{color:'#7B3C2E',fontSize:16,fontWeight:500}}>
List of Phoenix Talk
</span>
<br></br>
<br></br>
Pawna Kumari – A Fearless Crusader for Nature and Community
<br></br>
<br></br>
Bhawani Harijana – Crusade Towards Personal Hygiene, Health and Security for Women   
<br></br>
<br></br>
Rajim Didi – A Tale of Grit, And Undying Resolve to Uplift Dalits and Adivasis 
    <br></br>
<br></br>
Narayani Bhil – Crusader and Advocate for Marginalised Communities     
                 </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </div>
        </UncontrolledCollapse>
    
        </div>
        <div className='knowledge-sharing-session'>
    <div className='mt-3'>
    <Row  id="toggler3" style={{cursor:'pointer'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div' style={{background: '#E2A285'}}>
                        <p className='smi-about-the-project-div-text'>Cultural Events and Highlights</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler3">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
             <div className='mt-2'>
                <img className='toggler-img' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ile_img3.png'} />
            </div>
                </Col>
                <Col md="1">
                </Col>
            </Row>
            {/* <div>
                <img style={{width:'100%',height:'75vh',objectFit:'cover'}} src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ile_img1.png'} />
            </div> */}
            <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The event was interspersed with a range of cultural activities that provided a glimpse into the rich tapestry of global cultures and traditions. A Sufi music night and the “March of Diversity” were not just entertainment but symbolic representations of the unity and diversity inherent in the global fight for environmental conservation. The ‘Wish Tree’ installation, where participants shared their hopes and dreams, stood as a testament to the collective aspirations for a sustainable future.
                 </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </div>
        </UncontrolledCollapse>
    
        </div>
        <div className='knowledge-sharing-session'>
    <div className='mt-3'>
    <Row  id="toggler4" style={{cursor:'pointer'}}>
                    <Col md="12">
                    <div className='smi-about-the-project-div' style={{background: '#B08A82'}}>
                        <p className='smi-about-the-project-div-text'>Round Table</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <UncontrolledCollapse toggler="#toggler4">
    <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The Media Roundtable on Community Forest Rights was a critical element of the event, serving as a bridge between grassroots movements and broader public awareness. The roundtable highlighted the essential role of media in shaping public opinion and policy regarding forest rights and conservation. Discussions at the roundtable were comprehensive, covering the successes and challenges in the implementation of the Forest Rights Act, the empowerment of communities through forest rights, and the role of media in advocacy and awareness. This session underscored the symbiotic relationship between effective journalism and societal change, particularly in the context of environmental conservation.
<br></br>
<br></br>
The International Learning Exchange was a seminal event, not just for its scope but for its profound impact on the discourse surrounding forest conservation and community rights. It was a testament to the power of collaborative effort, education, and advocacy in shaping a future where humanity and nature coexist in harmony. The insights and experiences shared during the event have sown the seeds for radical and transformative forest futures, emphasizing our collective responsibility towards the planet, profits, and people.
</p>
             <div className='mt-2'>
                <img className='toggler-img' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ile_img2.png'} />
            </div>
                </Col>
                <Col md="1">
                </Col>
            </Row>
            {/* <div>
                <img style={{width:'100%',height:'75vh',objectFit:'cover'}} src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ile_img1.png'} />
            </div> */}
            <div className='pt-3'>
            <Row style={{alignItems:'flex-start',background: 'var(--Background, #F9F2EF)'}} className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                {/* <p className='hp_colab_text' style={{paddingTop:10,paddingLeft:10}}>About the event</p> */}
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The three-day event’s pinnacle was a vibrant display of unity, as participants from diverse communities in India and international delegates from Kenya, Tanzania, Cameroon, Malaysia, Nepal, Indonesia, and Brazil came together. This festive culmination featured a lively blend of singing, dancing, and music, symbolizing a shared vision of hope and solidarity. This joyous gathering underscored the event’s spirit of collaboration and mutual support across geographical and cultural boundaries.
                </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        </div>
        </UncontrolledCollapse>
    
        </div>
    </div>
<Events/>
    </div>
  )
}

export default ILE23