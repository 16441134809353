import React, { useLayoutEffect, useState } from 'react'
import bg from '../../assests/images/work_bg.png'
import Header from '../../components/Header2'
import { Row } from 'reactstrap'
import PublicationsList from './PublicationsList'
import StoriesList from './StoriesList'

function Stories() {
    const [size,setSize] = useState([]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)
    const items = [{}]
  return (
    <div>
    <div className='pubs-library-section'>
    <img src={bg} className="background-video-1"/>
    <Header background={false} modified={true}/>
    <div className='stories-library-container' style={{paddingLeft:50}}>
        <Row>
            {
                items.map((item)=>{
                    return(
            <div className='stories-header-card'>
                {/* <p className='pubs-card-date'> 10 May 24’</p> */}
                <p className='stories-card-title'>
                Today’s Story
                </p>
                <p className='stories-card-title-text'>
                We are making a change
                </p>
                <p  className='stories-card-desc'>
                mnis iste natus error sit voluptatem mnis iste natus error sit volupta mnis iste natus error sit  mnis iste natus error sit voluptatem mnis iste natus error sit volupta mnis iste natus error sit voluptatemmnis iste natus error sit voluptatem mnis iste natus error sit volupta mnis iste natus error sit voluptatem voluptatem.
                </p>
                <p className='stories-card-source'>
                mnis iste natus error sit voluptatem mnis iste natus error sit volupta mnis iste natus error sit voluptatem
                </p>
                {/* <p className='pubs-card-date1'> 10 May 24’</p> */}
            </div>
                    )
                })
            }
            
        </Row>
        </div>
        </div>
        <StoriesList/>
        </div>
  )
}

export default Stories