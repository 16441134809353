const eventsData = [{
    dateStart: '01',
    dateEnd : '03',
    Month :'Oct',
    year:'24',
    title:'Learning Exchange',
    location:'Delhi',
    url:'',
    isUpcoming:true,
},
{
    dateStart: '01',
    dateEnd : '03',
    Month :'Nov',
    year:'23',
    title:'International Learning Exchange',
    location:'Delhi',
    url:'/events/international-learning-exchange-2023',
    isUpcoming:false,
},
{
    dateStart: '09',
    dateEnd : '',
    Month :'June',
    year:'24',
    title:'Mohali nCount review meeting',
    location:'Mohali',
    url:'/events/ncount-training-mohali-2024',
    isUpcoming:false,
},
{
    dateStart: '15',
    dateEnd : '',
    Month :'February',
    year:'24',
    title:'Investment Roundtable',
    location:'Hyd',
    url:'',
    isUpcoming:false,
},
{
    dateStart: '09',
    dateEnd : '',
    Month :'June',
    year:'24',
    title:'Scientific Forest Management',
    location:'Mohali',
    url:'',
    isUpcoming:false,
},
{
    dateStart: '12',
    dateEnd : '',
    Month :'Dec',
    year:'23',
    title:'Training under Abua Bir Abua Dishom Abhiyan',
    location:'Jharkhand',
    url:'',
    isUpcoming:false,
},
{
    dateStart: '06',
    dateEnd : '',
    Month :'June',
    year:'23',
    title:'Empowering Voices of Young Tribal Girls',
    location:'Odisha',
    url:'',
    isUpcoming:false,
},
{
    dateStart: '23',
    dateEnd : '',
    Month :'May',
    year:'23',
    title:'ISB-TCS Carbon Measurement Workshop',
    location:'Jharkhand',
    url:'',
    isUpcoming:false,
},
{
    dateStart: '10',
    dateEnd : '12',
    Month :'May',
    year:'23',
    title:'Sal seeds collection training sessions',
    location:'Jharkhand',
    url:'',
    isUpcoming:false,
},
]

export default eventsData;