import React from 'react'
import Header from '../../../../components/Header2'
import { Col, Row } from "reactstrap";
import BlogsCarousel from './BlogsCarousel';
import { Link } from 'react-router-dom';

function CarbonPilot() {
    const tags = ['ISB - TCS','Technology','Collaboration']
  return (
    <div style={{overflow:'hidden'}}>
    <div className='home-landing-page'>
    <Header activeLink={'1'} background={true} modified={false}/>
    <Row className='home-page-title-row' style={{top:'95%'}}>
       
        {/* <p className='home-page-title'>Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring</p> */}
         {/* <p className='home-page-title-desc'>IoFE team guiding communities in inventory mapping with nCount.</p> */}
      </Row>
      <img  src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/carbon-landing-image.png'} className="background-video-cp"/>
    <div>
    </div>
    </div>
    <div>
        <Row>
            <Col md="1">
            </Col>
            <Col md="8" className='jharfra-text'>
            <p className='jamia-title'>IoFE team guiding communities in inventory mapping with nCount.</p>
            <p className='jharfra-desc'>{"The Initiative on Forest Economy (IoFE) is focused on making a business case for Community Forest Resource rights recognition in India by collaborating with various stakeholders as a Technical, Implementation, and Knowledge partner. ISB signed a three-year Memorandum of Understanding with TCS (Tata Consultancy Services LTD) in December 2022 to support the IoFE team’s leading efforts in developing a profitable business model for forest communities that includes sustainability. It is a three-year agreement that runs from October 2022 to October 2025."}</p>
<div>
    <p>The partnership aims to advance innovation in using technology to monitor forests and their products for societal development. The first objective is monitoring and estimating carbon stocks of the sub-tropical forests in the Simdega district of Jharkhand. Sky-Earth Convergence Technology approach developed by TCS will be extensively used to achieve this objective. </p>
</div>
<p className='jharfra-desc'>{"Jaldega, Bano, and Kolebira blocks of Simdega district are the chosen pilot districts of the project. "}</p>

<div className='jamia-desc-main-impact-heading'>
    <p className='jamia-desc-main-impact-heading-text'>Research Methodology of the Project: </p>
</div>
<p className='jharfra-desc'>{"Forest-related emission reductions and enhanced removals (carbon sequestration) are estimated by measurement, reporting, and verification (MRV) systems, usually based on a combination of remote sensing data, field or in situ measurements, and modeling approaches. Operationalizing the MRV process is lengthy, however, often taking years even in countries with high capacities for such a task, and once it is operational, it relies on a complex, non-standardized, uncertain, and lengthy process of integrating remote sensing and in situ measurements. This negatively affects the ability to address the drivers of these emissions and, at the same time, apply for and access climate finance in a timely manner. Hence, the mission of the project is to measure forest carbon stock accurately with the help of communities and technology and gain actionable insights. "}</p>

<p className='jharfra-desc'>{"The TCS Digital Platform for Next Gen Agriculture (TCS DNA) offers a unique Sky and Earth Convergence approach using large-scale data such as imagery by satellites and drones and earth data sets such as IoT (the Internet of Things), images, etc., for accuracy in carbon framing. It will monitor farm-level regenerative practices and measures carbon stocks every season at an accuracy level of 90% while reducing costs by up to 80%. The operationalization aspect of the pilot can be grouped into the following four areas"}</p>
<li style={{color:'#7B3C2E'}} className='jharfra-desc'>Data availability and access</li>
<li style={{color:'#7B3C2E'}} className='jharfra-desc'>Processing and computational performance; </li>
<li style={{color:'#7B3C2E'}} className='jharfra-desc'>Uncertainty management; and </li>
<li style={{color:'#7B3C2E',marginBottom:15}} className='jharfra-desc'>Standardization and protocols. </li>
<p className='jharfra-desc'>The collaboration will focus on assessing the readiness of various innovative technologies such as <span style={{color:'#7B3C2E'}}>remote sensing (RS), geostatistics (GS), artificial intelligence (AI), the Internet of Things (IoT), </span>and <span style={{color:'#7B3C2E'}}>cloud computing (CC).</span> This will help in identifying the combination of these tools that is ideal to foster a next-generation MRV. These tools will then accelerate our mission to unlock the potential of forests and enable governments and stakeholders, especially women, to monitor the implementation of environmental policies and assess the status of the forests. </p>
<p className='jharfra-desc'>Understanding the appropriate technology requirements to contribute to enhancing productivity and resource conservation is crucial for innovating solutions. The Initiative on Forest Economy will be able to empower the collectors of seasonal forest products, especially women, by developing such technologies. </p>
<Link to="/our-work" style={{textDecoration:'none'}}>
<button style={{marginBottom:10}} className="blog-details-button blog-details-button-mob-1">Back to all work</button>
</Link>
            </Col>
            <Col md="3">
            {
            tags.map((tag)=>{
                return (
                    <button className='tag-button'>{tag}</button>
                )
            })
        }
        <br></br>
        <center>
        <Link to="/our-work" style={{textDecoration:'none'}}>
        <button style={{marginBottom:10,marginTop:10}} className="blog-details-button blog-details-button-mob">Back to all work</button>
           </Link> 
        </center>
            </Col> 
            {/* <BlogsCarousel/> */}
        </Row>
    </div>
</div>
  )
}

export default CarbonPilot