import React, { useLayoutEffect, useState } from 'react'
import libraryBg from '../../assests/images/libraryBG.png'
import videoPreview from '../../assests/images/library_demo_video_preview.png';
import { Col, Row } from 'reactstrap'
import likeImg from '../../assests/icons/like.png';
import shareImg from '../../assests/icons/share.png';
import playButton from '../../assests/images/play.png';
import MyCarousel from '../home/Carousel';
function PubsList() {
    const items = [{},{},{},{},{}]
    const [size,setSize] = useState([]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)
  return (
    <div>

    <div className='library-video-section pubslist'>
        <img src={libraryBg} className="background-video"/>
        <div style={{width:'100%'}}>
          <MyCarousel bgColor={'#fff'}/>

    </div>
        </div>
        {
                size.length>0 && size[0]<768 ?
                <Row style={{background: 'var(--Background, #F9F2EF)'}}>
                    <Col md="12" style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <center>

                <p className='update-text-mob'>
                Want update on our every stories and blogs!
                </p>
                </center>
                    </Col>
                    <Col md="12" style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <div style={{display:'flex',flexDirection:'column',width:'fit-content'}}>
                <input className='update-text-input' placeholder='Your Email ID'/>
               <center style={{marginBottom:15}}>
                <button   className='update-text-subscrib-button'>Subscribe</button>
               </center>
                </div>
                    </Col>
                    </Row>
                    :
            
            <Row className='update-text-row' style={{background: 'var(--Background, #F9F2EF)'}}>
                <p className='update-text'>
                Want update on our every stories and blogs!
                </p>
                <div style={{display:'flex',flexDirection:'column',width:'fit-content'}}>
                <input className='update-text-input' placeholder='Your Email ID'/>
               <center>
                <button className='update-text-subscrib-button'>Subscribe</button>
               </center>
                </div>
            </Row>
}
        </div>
  )
}

export default PubsList