import React from 'react'
import Home from './Home'
import Projects from './Projects'
import Publications from './Publications'
import Events from './Events'
import Event2 from './Event2'
import Workshop from './Workshop'

function Main() {
  return (
    <div style={{overflowX:'hidden'}}>
      
    <Home/>
    <Projects/>
    <Publications/>
    <Events/>
    <Workshop/>
    </div>
  )
}

export default Main