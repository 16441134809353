const teamData = [{
    name: 'Aadithyan A ',
    profile: 'Market Linkage Specialist',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Aadithyan.png'
},
{
    name: 'Abhijeet Parmar',
    profile: 'Lead, Technology and Research  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Abhijeet.png'
},
{
    name: 'Abhinash Patra  ',
    profile: 'Geospatial Specialist  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Abhinash+Patra.png'
},
{
    name: 'Aditi Baranwal ',
    profile: 'Project Specialist - Odisha ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/ADiti.png'
},
{
    name: 'Ajaya Muduli ',
    profile: 'District Coordinator - Malkangiri ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Ajaya.png'
},
{
    name: 'Akshay Jasrotia ',
    profile: 'Consultant',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Akshay.png'
},
{
    name: 'Amit Upmanyu',
    profile: 'State Coordinator - Himachal Pradesh',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/amit.png'
},
{
    name: 'Ankur Raushan ',
    profile: 'Coordinator - Jharkhand ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/ankur.png'
},
{
    name: 'Apurva N S Duddu',
    profile: 'External Advisor',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Apurva.png'
},
{
    name: 'Aparna Gudipati ',
    profile: 'Project Specialist - Odisha ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/aparna.png'
},
{
    name: 'Arushi Jha ',
    profile: 'Research Associate  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Aarushi.png'
},
{
    name: 'Ashwini Chhatre',
    profile: 'Associate Professor and Executive Director of BIPP',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/prof.png'
},
{
    name: 'Augustina Soreng',
    profile: 'District Coordinator – Simdega, Jharkhand ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Augustina.png'
},
{
    name: 'Bhumika Morey',
    profile: 'GIS Specialist ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/bhumi.png'
},
{
    name: 'Chumki Behera  ',
    profile: 'Cluster Coordinator - Odisha ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/chumki.png'
},
{
    name: 'Hitish Kumar Palei  ',
    profile: 'District Coordinator  - Mayurbanj, Odisha  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/hitish.png'
},
{
    name: 'Jayanti Buruda   ',
    profile: 'District Coordinator - Odisha ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Jayanti.png'
},
{
    name: 'Kamini Singh ',
    profile: 'State Coordinator - Jharkhand ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Kamini.png'
},
{
    name: 'Lakshmi Swathi Gandham ',
    profile: 'Outreach and Communications Specialist  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Lakshmi.png'
},
{
    name: 'Naina Shukla  ',
    profile: 'Research Assistant  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/naina.png'
},
{
    name: 'Nani Babu ',
    profile: 'District Coordinator -– ASR, Andhra Pradesh ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Nani+Babu.png'
},
{
    name: 'Neha Chakravarty ',
    profile: 'Project Specialist ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/neha.png'
},
{
    name: 'Niharika Walia ',
    profile: 'Senior Design Specialist  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Niharika+Walia.png'
},
{
    name: 'Nitesh Saini',
    profile: 'Full Stack Developer  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Nitesh+Saini.png'
},
{
    name: 'Nitisha Jaiswal  ',
    profile: 'Program Coordinator ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Nitisha+Jaiswal.png'
},
{
    name: 'Pravali Pabbichetty ',
    profile: 'Sr. Project Associate ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Pravali.png'
},
{
    name: 'Rahul Dev Mahato ',
    profile: 'Cluster Coordinator – Jharkhand   ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Rahul.png'
},
{
    name: 'Ramesh Chand ',
    profile: 'Cluster Coordinator – Lahaul, Himachal Pradesh ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Ramesh.png'
},
{
    name: 'Ram Krishna Bhakat  ',
    profile: 'Field Coordinator – East Singhbhum, Jharkhand  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Ram+Krishna.png'
},
{
    name: 'Ravindra Chunarkar  ',
    profile: 'Project Specialist   ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Raviandar.png'
},
{
    name: 'Ravi Sadasivuni ',
    profile: 'Consultant',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/ravi.png'
},
{
    name: 'Renu Dewan ',
    profile: 'Data Assistant - Jharkhand ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Renu.png'
},
{
    name: 'Rohan Purkait  ',
    profile: 'Research Associate  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/rohan.png'
},
{
    name: 'Sandhya Singh  ',
    profile: 'Monitoring, Learning and Evaluation Specialist',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Sandhya.png'
},
{
    name: 'Sandip Chowdhury  ',
    profile: 'National Coordinator  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Sandeep.png'
},
{
    name: 'Sanjeev Kumar Kaushal  ',
    profile: 'Lead - Community Enterprise  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Sanjeev.png'
},
{
    name: 'Sanjoy Mondal  ',
    profile: 'Technology Specialist   ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/sanjoy.png'
},
{
    name: 'Satya Prasanna',
    profile: 'Advisor  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Bambam.png'
},
{
    name: 'Sayali Nitin Jadhav  ',
    profile: 'Spatial Data Coordinator  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Sayali.png'
},
{
    name: 'Shahid Mohammed ',
    profile: 'Senior Content Developer  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Shahid.png'
},
{
    name: 'Siddharth Kumar Yadav  ',
    profile: 'Full Stack Developer  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/sky.png'
},
{
    name: 'Sonam Chhukit ',
    profile: 'Cluster Coordinator – Spiti, Himachal Pradesh ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Sonam.png'
},
{
    name: 'Soumya Banerjee   ',
    profile: 'Senior Research Associate',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Soumya.png'
},
{
    name: 'Sushma Kattamuri    ',
    profile: 'Head - Strategic Initiatives  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/sushma.png'
},
{
    name: 'Surya Narayan Nayak  ',
    profile: 'Cluster Coordinator - Odisha ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/surya.png'
},
{
    name: 'Thochi Rengma ',
    profile: 'Consultant ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Tochi.png'
},
{
    name: 'Vijay Guleria  ',
    profile: 'Research Assistant  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Vijay.png'
},
{
    name: 'Vishal Jadkar ',
    profile: 'Coordinator - Community Enterprises  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/vishal.png'
},
{
    name: 'VV Satya Sainath Pilla ',
    profile: 'Andhra Pradesh PMU Lead  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/satya.png'
},
{
    name: 'Yamini   ',
    profile: 'Governance Specialist',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Yamini.png'
},
{
    name: 'Yashashree Garge  ',
    profile: 'Social Media Manager  ',
    url: 'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Team-Images/Yashashree.png'
},
{},
{},
{},
{}
]


export default teamData;