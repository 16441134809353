import React, { useLayoutEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import workData from '../../utils/workData';
import { Link } from 'react-router-dom';

function Home_landing_page() {
    const [size,setSize] = useState([]);
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      console.log("Size",size)
  return (
    <div style={size.length>0 && size[0]<768?null:{background:'var(--Background, #F9F2EF)',marginTop:'-50px'}}>
        <Row style={size.length>0 && size[0]<768?{marginTop:-50} :{paddingTop:60,paddingLeft:10,paddingRight:10}} >
            <Col md="2"></Col>
            <Col md="10" style={{background: 'var(--Background, #F9F2EF)'}}>
            <Row> 
             {
                 workData.map((card)=>{
                     return (
                        <Col md="4">
                          <Link style={{textDecoration:'none'}} to = {card.link}>
                         <div  className={size.length>0 && size[0]<768?'mob-home-pubs-division-1':'home-pubs-division-1'}>
                        <img src={card.img} className='home-pubs-division-image' />
                        <div className='home-pubs-division-texts-1'>
                        <p className='home-pubs-division-title'>{card.title}</p>
                        {/* <p className='home-pubs-division-desc'>{card.desc}</p> */}
                        </div>
                        </div>
                          </Link>
                        </Col>
                    )
                    })
                    }
                </Row>
               
        {/* <button className='work-home-map-button'>Choose your area of interest</button> */}
                
            </Col>
        </Row>
    </div>
  )
}

export default Home_landing_page