import React, { useLayoutEffect, useState } from 'react'
import logo from '../assests/logo/logo.png'
import brownLogo from '../assests/logo/brown_logo.png'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import blackToggle from '../assests/logo/black-toggle.png';
import whiteToggle from '../assests/logo/white-toggle.png';
import nav_logo from '../assests/logo/nav_logo.png';
import x_black from '../assests/icons/x_black.png'
import { 
	Navbar, 
	NavItem, 
	NavbarToggler, 
	Collapse, 
	NavLink, 
	Nav, 
	NavbarBrand 
} from 'reactstrap'; 
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

function Header({modified,background,activeLink}) { 
     console.log("modified",modified);
     const [size,setSize] = useState([]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)
	// Collapse isOpen State 
    const [active,setActive] = useState(0);
	const [isOpen, setIsOpen] = useState(false); 
    const navLinkItem = [
        {
          navLinkName: "Home",
          targerRef: "/",
        },
        {
          navLinkName: "Our Work",
          targerRef: "/our-work",
        },
        {
          navLinkName: "Library",
          targerRef: "/library",
        },
        {
          navLinkName: "About Us",
          targerRef: "/about-us",
        },
        {
          navLinkName: "Events",
          targerRef: "/events",
        },
      ];

      const toggle = () => setStatee(!isOpen);
    
   const [statee,setStatee] = useState(false);
	return ( 
		<div style={{ 
			display: 'block',
      position:'absolute',
      width:'-webkit-fill-available',
      zIndex:2
		}}> 
			<Navbar className={background==false?'header_Container_brown':'header_Container'} expand="md"> 
            <NavbarBrand>

      <img className='header_logo' src={(!modified)?logo:brownLogo}  alt='itff logo'/>
    </NavbarBrand>
				<NavbarToggler onClick={() =>setStatee(!statee)} ><img style={{width:20}} src={!modified?whiteToggle:blackToggle}/></NavbarToggler> 
     {
   size.length>1 && size[0]<768 &&
        <div className={`sidebar ${statee ? 'open' : ''}`}>
      <Navbar color="light" light expand="md" style={{height:'100vh',alignItems:'flex-start'}}>
     
      <Nav className="" style={{height:'85vh',justifyContent:'space-between',paddingLeft:10}} navbar> 
        <img style={{width:15}} onClick={()=>setStatee(!statee)} src={x_black}  className='close-button'/>
                       <div>
                        
                        {
                            navLinkItem.map((item,index)=>{
                                return(
						<NavItem style={{marginBottom:20}}>
             
              <Link style={{textDecoration:'none'}} to={item.targerRef}>
              <NavLink className={'mob-headerLink'}>{item.navLinkName}</NavLink> 
              </Link>
                            
						</NavItem> 
                                )
                            })
                        }
                        
                       </div>
                          <img className='header_logo_mob' src={nav_logo}  alt='itff logo'/>
					</Nav>
           {/* <button onClick={()=>setStatee(!statee)}>close</button> */}
      </Navbar>
    </div>
      }
				<Collapse isOpen={isOpen} navbar style={{justifyContent:'flex-end',zIndex:1000}}> 
        <Sidebar statee={statee} />
					<Nav className="ml-auto headerCont" style={{alignItems:'flex-end',zIndex:1000}} navbar> 
                        {
                            navLinkItem.map((item,index)=>{
                              console.log(activeLink,index)
                                return(
						<NavItem>
              {
                modified?
                <Link style={{textDecoration:'none'}} to={item.targerRef}>
							<NavLink style={activeLink==index?{color:'#7B3C2E'}:null} className={index==active?'headerLink1':'headerLink1'}>{item.navLinkName}</NavLink> 
                </Link>
            :
            <Link style={{textDecoration:'none'}} to={item.targerRef}>
            <NavLink style={activeLink==index?{fontWeight: '700'}:null} className={index==active?'headerLink':'headerLink'}>{item.navLinkName}</NavLink> 
            </Link>
            } 
						</NavItem> 
                                )
                            })
                        }
						    
					</Nav> 
				</Collapse> 
			</Navbar> 
		</div > 
	); 
} 

export default Header;
