import React from 'react'
import Header from '../../../../components/Header2'
import { Col, Row } from "reactstrap";
import BlogsCarousel from './BlogsCarousel';
import { Link } from 'react-router-dom';


function NcountTraining() {
    const tags = ['nCount Workshop','Technology','Fieldwork']
  return (
    <div style={{overflow:'hidden'}}>
    <div className='home-landing-page'>
    <Header activeLink={'1'} background={true} modified={false}/>
    <Row className='home-page-title-row' style={{top:'95%'}}>
       
        {/* <p className='home-page-title'>Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring</p> */}
         <p className='home-page-title-desc'>IoFE team guiding communities in inventory mapping with nCount.</p>
      </Row>
      <img  src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ncount-training.png'} className="background-video"/>
    <div>
    </div>
    </div>
    <div>
        <Row>
            <Col md="1">
            </Col>
            <Col md="8" className='jharfra-text'>
            <p className='jamia-title'>Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring</p>
            <p className='jharfra-desc'>{"On the 15th of February, 2024, a specialized Physical Training Program focusing on Inventory Mapping was hosted in Odisha( Jashipur, Mayurbhanj), leveraging the nCount platform. This program was directed towards imparting a detailed training regime on the intricacies of inventory mapping, employing an integrated approach of nCount with ODK Collect to both the personnel of Vasundhara and community volunteers. Convening within the confines of Vasundhara’s office, the session illuminated the critical importance of inventory mapping and its prospective applications, setting the stage for future engagements in ecological conservation and management."}</p>

<p className='jharfra-desc'>{"The program’s inclusive nature was highlighted by the participation of five Program Assistants hailing from a variety of districts, including Mayurbhanj, Deoghar, Kandhamal, Nayaghar, and Sambalpur, showcasing a commitment to diversity and collaborative learning. Following the necessary registration process, which required internet access, the assembled participants proceeded to a nearby forest. This field exercise demonstrated the practicality and efficiency of the nCount-facilitated data collection process, underscoring the platform’s user-friendliness and effectiveness."}</p>

<p className='jharfra-desc'>{"The subsequent engagement at Suanpal village within the Jashipur block further accentuated the benefits of inventory mapping. Dialogues with Vasundhara staff here were particularly poignant, focusing on the strategic use of nCount technology for bolstering future conservation and management projects. This was especially relevant considering the village’s acquisition of the Community Forest Resource (CFR) Title in 2014 and the ongoing efforts to establish a Community Forest Resource Management Committee (CFRMC), underlining a forward-looking approach to employing nCount technology for tangible community and ecological benefits."}</p>
<div className='jamia-desc-main-impact-heading'>
    <p className='jamia-desc-main-impact-heading-text'>About nCount</p>
</div>
<p className='jharfra-desc'>{"nCount stands as a beacon of innovation in the realm of ecological knowledge enhancement and the stewardship of natural resources. It melds the realms of gamification, remote sensing, and computer vision to cultivate an engaging, interactive platform that rejuvenates the processes involved in biodiversity data collection. The application of remote sensing technology within nCount provides for the real-time observation and analysis of ecosystems, offering indispensable insights for conservation activities. Moreover, the precision afforded by computer vision substantially improves the accuracy of species identification and population monitoring, thereby deepening the collective understanding of biodiversity dynamics. In essence, nCount not only accelerates biodiversity research but also strengthens governance models dedicated to the sustainable oversight of natural resources."}</p>

<p className='jharfra-desc'>{"Extending its utility beyond the conventional scope of forest ecosystems, nCount integrates visual object recognition modules with an Environmental DNA (eDNA) metabarcoding analysis component. This innovative combination is designed to streamline the assessment, evaluation, and surveillance of natural ecosystems, enhancing the platform’s capability to support comprehensive ecological studies and conservation strategies effectively."}</p>
<Link to="/our-work" style={{textDecoration:'none'}}>
<button style={{marginBottom:10}} className="blog-details-button blog-details-button-mob-1">Back to all work</button>
</Link>
            </Col>
            <Col md="3">
            {
            tags.map((tag)=>{
                return (
                    <button className='tag-button'>{tag}</button>
                )
            })
        }
        <br></br>
        <center>
        <Link to="/our-work" style={{textDecoration:'none'}}>
        <button style={{marginBottom:10,marginTop:10}} className="blog-details-button blog-details-button-mob">Back to all work</button>
           </Link> 
        </center>
            </Col> 
            {/* <BlogsCarousel/> */}
        </Row>
    </div>
</div>
  )
}

export default NcountTraining