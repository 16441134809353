import React from 'react'
import eventBg from '../../assests/images/eventsBg.png'
import mapIcon from '../../assests/logo/mapIcon.png';
import arrowIcon from '../../assests/logo/arrowIcon.png';

function Event2() {
  return (
    <div style={{position:'relative'}}>
     <img src={eventBg} className='home-event-section'/>
     <div style={{position:'absolute',top:'50%',left:'0%',width:'100vw'}}>
     <div className='home-event-card'>
            <div className='home-event-card-time'>
                <p className='home-event-card-date'>03</p>
                <p className='home-event-card-month'>May 24’</p>
            </div>
            <div className='home-event-card-desc-section'>
            <p className='home-event-card-desc'>Committees in Shoar and Shoon</p>
            </div>
            <p className='home-event-card-desc-text'>ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
            <div className='home-event-card-location-section'>
                <div className='home-event-card-location-section-map'>
                    <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                    <p className='home-event-card-location-section-map-location'>Pangi</p>
                </div>
                <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
            </div>
         </div>
         </div>
     </div>
  )
}

export default Event2