import React from 'react'
import Home from './Home'
import HomeUpdated from './HomeUpdated'
import AllRoundtables from './AllRoundtables'
import RoundTables from './RoundTables';
import linePlane from '../../assests/images/line-plane.png'
import Workshops from './Workshops';
import { Row } from 'reactstrap';
function Main() {
  return (
    <>
    
    <HomeUpdated/>
    <AllRoundtables/>
    <Workshops/>
    <div className='mt-3'>
        <Row style={{width:'100%',padding:0,margin:0}}>
           <img src={linePlane} style={{width:'100%',padding:0,margin:0}}/> 
        </Row>
    </div> 
    </>

  )
}

export default Main