import React from 'react'
import Header from '../../../../components/Header2'
import { Col, Row } from "reactstrap";
import BlogsCarousel from './BlogsCarousel';
import { Link } from 'react-router-dom';

function JharFRA_Training_In_Potka() {
    const tags = ['Forest Governance ','Jharkhand','JharFRA','Potka']
    return (
      <div style={{overflow:'hidden'}}>
      <div className='home-landing-page'>
      <Header activeLink={'1'} background={true} modified={false}/>
      <Row className='home-page-title-row' style={{top:'95%'}}>
         
          <p className='home-page-title'> JharFRA Training in Potka </p>
           {/* <p className='home-page-title-desc'>IoFE team guiding communities in inventory mapping with nCount.</p> */}
        </Row>
        <img  src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/Blogs/JHARFRA+TRAINING+IN+POTKA.png'} className="background-video"/>
      <div>
      </div>
      </div>
      <div>
          <Row>
              <Col md="1">
              </Col>
              <Col md="8" className='jharfra-text'>
              <p className='jamia-title'>About the project</p>
              <p className= 'hp_colab_left_border' style={{fontSize:13}}>{"Women make up much of the seasonal collectors of forest produce and sell in the local market to supplement their incomes. The Initiative on Transformative Forest Futures (ITFF) by BIPP-ISB envisions to empower women by creation of women-led forest enterprises. The first step in fulfilling this vision is the recognition of community forest rights (CFRR).  "}</p>
              <p className='jharfra-desc'>{"The ITFF has developed JharFRA, a digital application in consultation with the state designed to streamline the process of filing community forest resource rights claims. This innovation aims to enhance efficiency and scalability in managing these claims. The government of Jharkhand has adopted the application under the campaign, Abua Bir Abua Dishom Abhiyaan to upscale the process of community forest resource rights in the state. The rationale is to make use of the digital application to saturate the CFRR claims at scale. "}</p>
  
  <p className='jharfra-desc'>{"The team met with the District Welfare Officer (DWO) of East Singhbhum in Jharkhand to discuss the district’s progress under ABAD Abhiyan. It was recognized that there was a need to accelerate the efforts and we advocated for a continuous review of Bir bandhus. To streamline the claim generation process, we proposed a phase-wise review cum training approach spanning three months, beginning with registration on the JharFRA Application."}</p>
  <div className='jamia-desc-main-impact-heading'>
      <p className='jamia-desc-main-impact-heading-text'>Timeline of events</p>
  </div>
  <p className='jharfra-desc'>{"On 22nd April 2024, the ITFF team conducted a JharFRA training workshop in the Potka block of the district. The training discussed the process of CFRR from claim filing to title entitlement and the role of JharFRA in the CFRR claim process. We invited one representative from each Forest Rights Committees (either president or secretary). Over 100 people attended the training program with over 94 FRC members attending the training workshop. Some of them even brought their CFRR documents with required Gram Sabha signatures. While discussing the process of claim filing on the app, we tried to provide visual context by showing them how to register themselves on JharFRA. The village communities displayed great enthusiasm during the training workshop and were highly receptive to using JharFRA. "}</p>
  
  <p className='jharfra-desc'>{"We asked the attendees to download the application on their mobiles and walked them through the process of claim generation. However, it was also discovered village level data and CFR maps required in the application to progress to the next stage were missing. We intimated the information to our tech team and our technology team immediately started on creating resolving the issues. Similar workshops are also planned for other blocks to ensure comprehensive coverage.  "}</p>

<p className='jharfra-desc'>On 9th May 2024, as a part of our focused intervention in the district, a block-level review cum workshop on CFRR claim generation was organized at Dumaria block of East Singhbhum district. The workshop was attended by Bir Bandhus and FRC members. There are 10 BB and 68 FRCs in the block. The meeting saw participation from all 10 BB and 55 FRCs. During the workshop, these FRCs were registered on JharFRA and the attendees were trained on the CFR claim process up to phase 1.  </p>
<p className='jharfra-desc'>{"The Bir Bandhus demonstrated active engagement during the workshop and expressed interest in supporting FRCs in the CFR claim generation process. However, they indicated the need for financial support to cover at least their travel expenses. Providing financial incentives to cover these small expenses could greatly enhance their contribution to the process. "}</p>
  <Link to="/our-work" style={{textDecoration:'none'}}>
  <button style={{marginBottom:10}} className="blog-details-button blog-details-button-mob-1">Back to all work</button>
  </Link>
              </Col>
              <Col md="3">
              {
              tags.map((tag)=>{
                  return (
                      <button className='tag-button'>{tag}</button>
                  )
              })
          }
          <br></br>
          <center>
          <Link to="/our-work" style={{textDecoration:'none'}}>
          <button style={{marginBottom:10,marginTop:10}} className="blog-details-button blog-details-button-mob">Back to all work</button>
             </Link> 
          </center>
              </Col> 
              {/* <BlogsCarousel/> */}
          </Row>
      </div>
  </div>
    )
  }
  


export default JharFRA_Training_In_Potka