import React, { useLayoutEffect, useState } from 'react'
import demoPubs from '../../../assests/images/demo-pubs.png'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom';
import workData from '../../../utils/workData';

function Field_landing_Page({location,titleDesc,allLocations}) {
    const items = workData.filter((work)=>work.location==location);
    const [size,setSize] = useState([]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)
  return (
    <div className='field-landing-page'>
        <Row>
            <Col md="1"></Col>
            <Col md="4" className='mob-field-location-col'>
            <div className='mob-field-location'>
            <p className='field-desc-title'>{location}</p>
            <p className='field-desc-desc'>{titleDesc}</p>
            </div>
            {allLocations?.map((location)=>{
              return(
            <div className='mob-field-location'>
            <p className='field-desc-location-title'>{location?.title}</p>
            <p className='field-desc-location-desc'>{location?.desc} </p>
            {/* <button className='field-desc-location-button'>Know More</button> */}
            </div>
              )
            })}
            {/* <div className='mob-field-location'>
            <p className='field-desc-location-title'>Location 2</p>
            <p className='field-desc-location-desc'>Ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. ed ut perspiciatis </p>
            <button style={{marginBottom:10}} className='field-desc-location-button mob-field-desc-location-button'>Know More</button>
            </div> */}
            {/* <div className='mob-field-location'>
            <p className='field-desc-location-title'>Location 3</p>
            <p className='field-desc-location-desc'>Ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. ed ut perspiciatis </p>
            <button className='field-desc-location-button mob-field-desc-location-button'>Know More</button>
            </div> */}
            </Col>
            <Col md="1"></Col>
            <Col md="6">
            <Row> 
             {
                 items.map((work)=>{
                     return (
                         <Col md="6">
                        <Link style={{textDecoration:'none'}} to={work.link2}>
                         <div className={size.length>0 && size[0]<768?'mob-home-pubs-division-1':'home-pubs-division-1'}>
                        <img src={work.img} className='home-pubs-division-image' />
                        <div className='home-pubs-division-texts-1'>
                        <p className='home-pubs-division-title'>{work.title}</p>
                        {/* <p className='home-pubs-division-desc'>{work.desc}</p> */}
                        </div>
                        </div>
                        </Link>
                        </Col>
                    )
                    })
                    }
                </Row>
                {/* <button className='work-home-map-button'>Choose your area of interest</button> */}
            </Col>
        </Row>
    </div>
  )
}

export default Field_landing_Page