import React from 'react'
import MyCarousel from './Carousel'
import { Link } from 'react-router-dom'

function Publications() {
  return (
    <div className='home-pubs-section' style={{marginTop:30}}>
        <center>
        <p className='home-publication-title'>Building Multi-stakeholder Partnership</p>
        <p className='home-publication-desc'>
        We are forging partnerships with government agencies, civil society organizations, research institutions, and private companies to transform forest landscapes into hubs of opportunity for wealth creation. 
        </p>
        </center>
        <MyCarousel/>
        <center><Link to="/library"><button className='home-page-button mb-5'>Check out our Library</button></Link></center>
    </div>
  )
}

export default Publications