import React from 'react'
import { Col, Form, Input, Row } from 'reactstrap'
import Contact_Vector from '../../assests/icons/contact_vector.png'

function ContactForm() {
  return (
    <div>
        <Row className='mt-5'>
<Col xs="12" md="6">
<p className='about-team-contact-title'>Want to contact any of us!</p>
<p className='about-team-contact-desc'>If you have any query to any of us! You can write to us and we will be in touch as soon as possible. </p>
<img src={Contact_Vector}  className='about-team-contact-image'/>
</Col>
<Col md="6">
<Form className='about-team-form'>
    <Input placeholder='Full Name' className='about-contact-input'/>
    <Input placeholder='Institution/Organization Name  ' className='about-contact-input'/>
    <Input placeholder='Name of the person you want to connect to' className='about-contact-input'/>
    <Input placeholder='Write your query' type='textarea' className='about-contact-input'/>
    <Input placeholder='Your Email ID' className='about-contact-input'/>
    <button className='about-contact-input-submit'>Send</button>
</Form>
</Col>
        </Row>
    </div>
  )
}

export default ContactForm