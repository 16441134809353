import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

function AllRoundtables() {
  return (
    <div style={{background:'#F9F2EF',paddingBottom:25}}>
        <Row className='event-roundtables-row'>
          <p className='event-roundtables-title'>Roundtables</p>
          <p className='event-roundtables-desc'>We are forging partnerships with government agencies, civil society organizations, research institutions, and private companies to transform forest landscapes into hubs of opportunity for wealth creation. </p>
        </Row>
        <Row className='event-roundtables-row-1'>
                <Col md="9" style={{display:'flex'}}>
            <Row>
            <Col  xs="6" md="4" className='event-roundtables-row-1-col'>
            <Link style={{textDecoration:'none'}} to="/round-table/The Forests’ Bioenergy Opportunity: Investments Powering Sustainability">
            <div className='event-roundtable-1-desc'>
                <p className='event-roundtable-1-desc-text'>The Forests’ Bioenergy Opportunity: Investments Powering Sustainability</p>
            </div>
            </Link>
            </Col>
            <Col xs="6" md="4" className='event-roundtables-row-1-col'>
            <div className='event-roundtable-1-desc'>
                <p className='event-roundtable-1-desc-text' style={{marginLeft:15,marginTop:10}}>Andhra Pradesh RoundTable: Bamboo Forest Landscapes Sustainable Thriving Forest Economy through Security of Tenure </p>
            </div>
            </Col>
            <Col xs="6" md="4" className='event-roundtables-row-1-col'>
            <div className='event-roundtable-1-desc'>
                <p className='event-roundtable-1-desc-text' style={{marginLeft:15}}>Design and Communication for Sustainable Forest Economy </p>
            </div>
            </Col>
            <Col xs="6" md="4" className='event-roundtables-row-1-col'>
            <div className='event-roundtable-1-desc'>
                <p className='event-roundtable-1-desc-text' style={{marginLeft:15}}>Technology for Sustainable Forest Economy </p>
            </div>
            </Col>
            <Col xs="6" md="4" className='event-roundtables-row-1-col'>
            <div className='event-roundtable-1-desc'>
                <p className='event-roundtable-1-desc-text' style={{marginLeft:10,marginTop:10}}>Roundtable on Forests as sustainable and secure sources of energy & fossil fuel substitutes </p>
            </div>
            </Col>
            <Col xs="6" md="4" className='event-roundtables-row-1-col'>
            <div className='event-roundtable-1-desc'>
                <p className='event-roundtable-1-desc-text' style={{marginLeft:12,marginTop:10}}>Boosting the Forest Economy of Himachal Pradesh: A Roundtable Discussion on Security of Tenure</p>
            </div>
            </Col>
            </Row>
            </Col>
        </Row>
    </div>
  )
}

export default AllRoundtables