import React from 'react'
import Header from '../../components/Header2'
import { Row } from 'reactstrap'

function Home() {
  return (
    <div className='home-landing-page'>
    <Header background={false} activeLink={'3'} modified={false}/>
      <Row className='home-page-title-row'>
       
      
      </Row>
<video className="background-video" autoPlay={true} loop muted>
    <source src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Group_1+1.mp4'} type="video/mp4" />
  </video> 
    <div>
    </div>
</div>
  )
}

export default Home