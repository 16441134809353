import React, { useLayoutEffect, useState } from 'react'
import bg from '../../../assests/images/work_bg.png'
import Header from '../../../components/Header2';
import mobBg from '../../../assests/images/workBG.png';
import { Row } from 'reactstrap';
import Field_landing_Page from './Field_landing_Page';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../../utils/CustomDropdown';
function Goa() {
    const [size,setSize] = useState([]);
    const [interest,setInterest] =useState(null)
    const navigate = useNavigate();
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      console.log("Size",size)
      const placeholderStyle = {
        color: '#000',
        fontSize:'11px',
        paddingLeft:'-15px',
        fontFamily: "Poppins",
        fontWeight:500,
      };
      const inputStyle1={
        borderRadius: "15px",
        boxShadow: "none",
        marginTop:20,
        paddingLeft:0,
        height:10,
        fontWeight:600,
        width:'70%',
        color:'#7B3C2E',
  background: 'radial-gradient(84.52% 173.9% at 23.57% 0%, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)',
        fontSize:14,
        border: '1.35px solid white',
      }
      const inputStyle={
        borderRadius: "15px",
        boxShadow: "none",
        marginTop:20,
        paddingLeft:0,
        height:10,
        fontWeight:500,
        color:'#7B3C2E',
        fontSize:14,
        border: '1.35px solid white',
      }
      const organizations = [
        { value: 'Odisha', label: 'Odisha' },
        { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
        { value: 'Jharkhand', label: 'Jharkhand' },
        { value: 'Maharashtra', label: 'Maharashtra' },
        {value:'Andhra Pradesh',label:'Andhra Pradesh'}
      ];
      const handleChange = (selectedOption) => {
        if(selectedOption.value=='Odisha'){
          navigate('/our-work/Odisha')
          }
          if(selectedOption.value=='Himachal Pradesh'){
            navigate('/our-work/himachal-pradesh')
          }
          if(selectedOption.value=='Jharkhand'){
            navigate('/our-work/jharkhand')
          }
          if(selectedOption.value=='Maharashtra'){
            navigate('/our-work/maharashtra')
          }
          if(selectedOption.value=='Goa'){
            navigate('/our-work/goa')
          }
          if(selectedOption.value=='Andhra Pradesh'){
            navigate('/our-work/andhra-pradesh')
          }
          setInterest(selectedOption.value)
      };

      const titleDesc="ITFFs initiatives will leverage digital and mechanical innovations to enhance efficiency and scalability, while also establishing vital market connections with large industrial buyers. Furthermore, this initiative seeks to establish comprehensive end-to-end business value chains, placing communities with secure tenure rights at the forefront of the model, thereby making them key stakeholders in a sustainable economic framework. "
      const allLocations =[
        {
          title:'Pangi  ',
          desc:'In the Pangi Valley of Chamba district, we facilitated CFR claim filing, forest boundary mapping, committee formation, and management plans. Through ITFF initiatives, patwaris, women, and SDLC members received training in participatory forest inventory mapping.'
        },
        {
          title:'Lahual and Spiti  ',
          desc:"BIPP, ISB has also signed MoUs with the Lahaul and Spiti district administration. Our project will facilitate capacity building for FRCs, CFRR claim filing, and forest boundary and inventory mapping harnessing nCount in the region. "
        },
        {
          title:'Chota Banghal ',
          desc:'In 2020, 28 Gram Sabhas in Chota Banghal received community forest resource titles and began developing a sustainable harvesting plan for seasonal forest products. They formed the Chota Banghal Maha Gram Sabha, supported by Kishan Sabha and ITFF, to create CFRMCs and CFRMPs. Our project also is currently assisting the CBMGS with market linkages for SFPs. '
        }
      ]

    return (
      <div style={{overflow:'hidden'}}>
        {
            size.length>0 && size[0]<768 ?
            <div className='mob-work-home-section'>
               <img src={mobBg} className="mob-background-video-1"/>
               <Header modified={false} background={false}/>
               <div className='mob-work-map-container'>
               <div style={{position:'relative'}}>
               <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of ITFF"}/>
               <div className='map-image-text'>
                <p className='mob-map-image-title'>Goa</p>
                <p className='mob-map-image-desc'>ITFF has partnered with the Government of Goa through a MoU to transform the state's forests into essential bioenergy hubs, with the aim of enhancing energy security, fostering rural development, and mitigating climate change. At the core of this collaboration is the development of a bamboo economy, with a particular focus on empowering women to lead community enterprises.</p>
                {/* <img src={mapLocations} style={{width:'100%'}}/> */}
            </div>
               </div>
               </div>
              </div>
              :
              <div className='work-home-section'>
              <img src={bg} className="background-video-1"/>
              <Header activeLink='1' background={false} modified={true}/>
                 {/* <div className='work-map-dropdown-div'>
                 <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of ITFF"}/>
                   </div> */}
              <div className='work-map-container'>
               <div style={{position:'relative'}}>
               <img src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-goa.png'} className='work-map-image'/>
               {/* </Link> */}
               </div>
               <div className='map-image-text'>
                   <p className='field-map-image-title'>
                   Goa</p>
                   <p className='map-image-desc' style={{width:600}}>ITFF has partnered with the Government of Goa through a MoU to transform the state's forests into essential bioenergy hubs, with the aim of enhancing energy security, fostering rural development, and mitigating climate change. At the core of this collaboration is the development of a bamboo economy, with a particular focus on empowering women to lead community enterprises.  
                    </p>
                   <Dropdown inputStyle={inputStyle1}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of ITFF"}/>
                   <br></br>
               </div>
              </div>
               </div>
       }
        <Field_landing_Page location='Goa' titleDesc={titleDesc} allLocations={[]}/>
        </div>
    )
  }

export default Goa