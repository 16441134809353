import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Container,Row,Col } from 'reactstrap'
import demoImage from '../../assests/images/demo-project-1.png'
import demoImage2 from '../../assests/images/demo-projects2.png';
import {Dimensions} from 'react';
import MyCarousel from './Carousel';
import ProjectCarousel from './ProjectCarousel';
import { Link } from 'react-router-dom';

function  Projects() {
  const allProjects = [{
    name: ''
  }]
  const [size,setSize] = useState([]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  console.log("Size",size)
  return (
    <div className='home-project-section' style={size[0]<500?{display:'flex'}:null}>
      
        <Row>
           
          <Col md="4" className='home-project-desc-section'>
             <div style={{padding:'10px 55px 10px 55px'}}>
{size.length>0 && size[0]<768?null:<p className='home-project-text'>Our Initiatives</p>}
{size.length>0 && size[0]<768?null:<p className='home-project-title-desc'>Though multiple initiatives, we aim to produce evidence and recipes for creating replicable models of large-scale development and economic well-being in rural landscapes.</p>}
            {size[0]>768 &&  <Link to="/our-work" ><button className='home-project-button'>All Works</button></Link>}
             </div>
          </Col>
          {
            size.length>0 && size[0]<768?
            <Col md="12">
              <center>
              <p className='home-project-text'>Our Projects</p>
              </center>
              <ProjectCarousel/>
              <center>
              <p className='home-project-title-desc-mob'>Though multiple initiatives, we aim to produce evidence and recipes for creating replicable models of large-scale development and economic well-being in rural landscapes.</p>
             <Link to="/our-work" ><button style={{marginBottom:35}} className='home-project-button'>All Projects</button></Link>
              </center>
            </Col>
            
            :
          
          <Col md="8" className='home-project-details-section'>
          <Container>
            <Row className='home-project-details-section-row'>
              <Col md="3" className='home-project-column'>
              <Link to="/our-work/jharkhand/jharfra-launch">
                  <img className='home-project-image' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jharfra.png'} />
              </Link>
              </Col>
              <Col md="3" className='home-project-column'>
              <Link style={{textDecoration:'none'}}  to="/our-work/odisha/jungle-rani">
                  <div className='home-project-desc'>
                    <p className='home-project-desc-title'>Empowering Women-Led Enterprises </p>
                    <p className='home-project-desc-description'>Women-led FPCs and primary collectors from Gumla and Ranchi, Jharkhand, formed their first direct industry partnership by selling Sal seeds to Swedish company AAK.</p>
                  </div>
                  </Link>
              </Col>
              <Col md="3" className='home-project-column'>
              <Link to="/our-work/himachal-pradesh/ncount-training">
                  <img className='home-project-image' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/ncount-main-page.png'} />
              </Link>
              </Col>
              <Col md="3" className='home-project-column'>
              <Link style={{textDecoration:'none'}} to="/our-work/Goa/Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa">
                  <div className='home-project-desc'>
                  <p className='home-project-desc-title mt-3'>ISB-Government of Goa MoU</p>
                    <p className='home-project-desc-description'>ITFF and the Goa government have signed an MoU to develop evidence-based policies, empowering forest communities and women-led enterprises through technology, sustainability, and market linkages.</p>
                  </div>
              </Link>
              </Col>
              <Col md="3" className='home-project-column'>
              <Link style={{textDecoration:'none'}}  to="/our-work/jharkhand/jharfra-launch">
                  <div className='home-project-desc'>
                  <p className='home-project-desc-title'>Launch of JharFRA</p>
                    <p className='home-project-desc-description'>The JharFRA application has been adopted by GOJ’s Abua Bir Abua Dishom Campaign, to file CFR claims for over 1 lakh individuals through state-civil society collaboration.  </p>
                  </div>
              </Link>
              </Col>
              <Col md="3" className='home-project-column'>
              <Link to="/our-work/odisha/jungle-rani">
                  <img className='home-project-image' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/empowering+women.png'} />
              </Link>
              </Col>
              <Col md="3" className='home-project-column'>
              <Link style={{textDecoration:'none'}} to="/our-work/himachal-pradesh/ncount-training">
                  <div className='home-project-desc'>
                  <p className='home-project-desc-title mt-2'>Harnessing nCount for Sustainability</p>
                    <p className='home-project-desc-description'>The Himachal Pradesh Forest Department's use of nCount will inventory herbs and medicinal plants, enhancing scientific knowledge and guiding policies for ecology, biodiversity, and community needs. </p>
                  </div>
              </Link>
              </Col>
              <Col md="3" className='home-project-column'>
              <Link to="/our-work/Goa/Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa">
                  <img className='home-project-image' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/Gao-mou.png'} />
              </Link>
              </Col>
            </Row>
            </Container>
          </Col>
}
        </Row>
    </div>
  )
}

export default Projects