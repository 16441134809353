import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Col, Row } from 'reactstrap' 
import demoImg from '../../assests/images/demo-project-1.png';
import { Link } from 'react-router-dom';

function OurStories() {
  const [size,setSize] = useState([]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)


    const cards = [{
      title : 'Our Vision',
      img:demoImg,  
    },
  {
    title : 'Our Mission',
      img:demoImg,
  }]

  const [activeCard,setActiveCard]  =useState(0);

  const [bit, setBit] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (activeCard == 0) setActiveCard(1);
      else setActiveCard(0);
      setBit(!bit);
    }, 3000);
  }, [bit]);

  return (
    <div style={{width:'100vw',overflowX:'hidden',background:'#D9AC98',padding:10,overflow:'hidden'}}>
       {
        size.length>0 && size[0] <768 ?
        <Row>
          <Col md="4" xs="12" style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div  style={{display:'flex',justifyContent:'center'}}>
              <p className='our-stories-mob'>Our Stories</p>
              </div>
              <div>
                <div style={{display:'flex',justifyContent:'center'}}>
               <div className='our-vision-div-mob'>
                <p className='our-stories-mob' style={{color:'#7B3C2E',marginBottom:0}}>{cards[activeCard].title}</p>
                </div>
               </div>
               <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
               <img src={cards[activeCard].img} style={{width:'85%',height:350,objectFit:'cover'}}/>

               </div>
               <div  style={{display:'flex',justifyContent:'center',marginTop:10}}>
               <Link to="/library/stories">
               <button className='all-stories'>
            All Stories
            </button>
            </Link>
            </div>
              </div>

          </Col>
        </Row>
        :
        <Row>
            <Col md="3" className='our-stories-col'>
            <p className='our-stories'>Our Stories</p>
            <p className='our-stories-desc'>
            Ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae 
            </p>
            <Link to="/library/stories">
            <button className='all-stories'>
            All Stories
            </button>
            </Link>
            </Col>
            <Col md="9">
            <Row>
             <Col md="3">
             <img src={demoImg} style={{width:'100%',height:250,objectFit:'cover'}}/>
             </Col>
             <Col md="3" className='our-vision'>
              <p className='our-vision-text'>Our Vision</p>
             </Col>
             <Col md="3">
             <img src={demoImg} style={{width:'100%',height:250,objectFit:'cover'}}/>
             </Col>
             <Col md="3" className='our-vision'>
             <p className='our-vision-text'>Our Mission</p>
             </Col>
            </Row>
            </Col>
        </Row>
}
    </div>
  )
}

export default OurStories