import React, { useLayoutEffect, useState } from 'react'
import mapIcon from '../../assests/logo/mapIcon.png';
import bg from '../../assests/images/eventsBg.png'
import arrowIcon from '../../assests/logo/arrowIcon.png';
import bg_video from '../../assests/images/Background_wave.mp4'
import { Col, Row,Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import eventsData from '../../utils/EventsData';

function Events() {
    const [size,setSize] = useState([]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)
   
  return (
    <div className='home-events-section'>
        <img src={bg} className="background-video"/>
      <Row style={{alignItems:'flex-end',width:'100vw'}}>
        {
            size[0]<600 ?
            <div style={{display:'flex',overflowX:'scroll',width:'100vw',paddingLeft:0}}>
                {
                    eventsData.map((event)=>{
                        return (
                            <div className='home-event-card-mob'>
    
                            <div style={{height:'70%'}}>
                        <div className='home-event-card-time'>
                            <p className='home-event-card-date-mob'>{event?.dateStart}{event?.dateEnd!=''&&`-${event?.dateEnd}`}</p>
                            <p className='home-event-card-month'>{event?.Month}’{event?.year}</p>
                        </div>
                        <div className='home-event-card-desc-section'>
                        <p className='home-event-card-desc'>{event?.title}</p>
                        </div>
                        </div>
                        {/* <p className='home-event-card-desc-text'>ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p> */}
                        <div className='home-event-card-location-section' style={{marginTop:37}}>
                            <div className='home-event-card-location-section-map'>
                                <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                                <p className='home-event-card-location-section-map-location'>{event?.location}</p>
                            </div>
                            <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
                        </div>
                     </div>
                        )
                    })
                }
                {/* <div className='home-event-card home-event-card-mob'>
                <Link style={{textDecoration:'none'}} to="/events/international-learning-exchange-2023">
            <div className='home-event-card-time'>
                <p className='home-event-card-date'>01-03</p>
                <p className='home-event-card-month'>Oct’ 24</p>
            </div>
            <div className='home-event-card-desc-section'>
            <p className='home-event-card-desc'>International Learning exchange’23</p>
            </div>
            <p className='home-event-card-desc-text'>ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
            <div className='home-event-card-location-section' style={{marginTop:37}}>
                <div className='home-event-card-location-section-map'>
                    <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                    <p className='home-event-card-location-section-map-location'>Delhi</p>
                </div>
                <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
            </div>
                </Link>
         </div> */}
         {/* <div className='home-event-card home-event-card-mob'>
            <div className='home-event-card-time'>
                <p className='home-event-card-date'>15</p>
                <p className='home-event-card-month'>February’24</p>
            </div>
            <div className='home-event-card-desc-section'>
            <p className='home-event-card-desc'>Investment Roundtable</p>
            </div>
            <p className='home-event-card-desc-text'>ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
            <div className='home-event-card-location-section' style={{marginTop:60}}>
                <div className='home-event-card-location-section-map'>
                    <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                    <p className='home-event-card-location-section-map-location'>Hyd</p>
                </div>
                <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
            </div>
         </div>
         <div className='home-event-card home-event-card-mob'>
            <Link style={{textDecoration:'none'}} to="/events/ncount-training-mohali-2024">
            <div className='home-event-card-time'>
                <p className='home-event-card-date'>09</p>
                <p className='home-event-card-month'>June’24</p>
            </div>
            <div className='home-event-card-desc-section'>
            <p className='home-event-card-desc'>Mohali nCount review meeting</p>
            </div>
            <p className='home-event-card-desc-text'>ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
            <div className='home-event-card-location-section' style={{marginTop:60}}>
                <div className='home-event-card-location-section-map'>
                    <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                    <p className='home-event-card-location-section-map-location'>Mohali</p>
                </div>
                <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
            </div>
            </Link>
         </div> */}
            </div>
            :
        
            <Col md="7" sm="12" style={{display:'flex',justifyContent:'center'}}>
            <div className='home-event-card-division'>
            {/* <div className='home-event-card'>
            <Link style={{textDecoration:'none'}} to="/events/international-learning-exchange-2023">
            <div className='home-event-card-time'>
                <p className='home-event-card-date'>01-03</p>
                <p className='home-event-card-month'>Oct' 24</p>
            </div>
            <div className='home-event-card-desc-section'>
            <p className='home-event-card-desc'>International Learning exchange’23</p>
            </div>
            <p className='home-event-card-desc-text'>ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
            <div className='home-event-card-location-section' style={{marginTop:60}}>
                <div className='home-event-card-location-section-map'>
                    <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                    <p className='home-event-card-location-section-map-location'>Delhi</p>
                </div>
                <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
            </div>
            </Link>
         </div> */}
         {
            eventsData.map((event)=>{
 return (
    <Link style={{textDecoration:'none'}} to={event?.url}>
    <div className='home-event-card home-event-card-1' style={event?.isUpcoming ?{background:'#ea7055'} :{}}>
            <div style={{height:'70%'}}>
            <div className='home-event-card-time'>
                
                <p className='home-event-card-date'>{event?.dateStart}{event?.dateEnd!=''&&`-${event?.dateEnd}`}</p>
                <p className='home-event-card-month'>{event?.Month}’{event?.year}</p>
            </div>
            <div className='home-event-card-desc-section'>
            <p className='home-event-card-desc'>{event?.title}</p>
            </div>
            </div>
            <div className='home-event-card-location-section-1'>
                <div className='home-event-card-location-section-map'>
                    <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                    <p className='home-event-card-location-section-map-location'>{event?.location}</p>
                </div>
                <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
            </div>
         </div>
         </Link>
 )
            })
         }
         {/* <div className='home-event-card home-event-card-1'>
            <div className='home-event-card-time'>
                <p className='home-event-card-date'>01-03</p>
                <p className='home-event-card-month'>Oct’24</p>
            </div>
            <div className='home-event-card-desc-section'>
            <p className='home-event-card-desc'>Learning Exchange</p>
            </div>
            <p className='home-event-card-desc-text'>ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
            <div className='home-event-card-location-section-1'>
                <div className='home-event-card-location-section-map'>
                    <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                    <p className='home-event-card-location-section-map-location'>Hyd</p>
                </div>
                <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
            </div>
         </div>
         <div className='home-event-card home-event-card-1'>
            <Link style={{textDecoration:'none'}} to="/events/international-learning-exchange-2023">
            <div className='home-event-card-time'>
                <p className='home-event-card-date'>01-03</p>
                <p className='home-event-card-month'>Oct' 24</p>
            </div>
            <div className='home-event-card-desc-section'>
            <p className='home-event-card-desc'>International Learning exchange’23</p>
            </div>
            <p className='home-event-card-desc-text'>ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
            <div className='home-event-card-location-section' style={{marginTop:60}}>
                <div className='home-event-card-location-section-map'>
                    <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                    <p className='home-event-card-location-section-map-location'>Delhi</p>
                </div>
                <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
            </div>
            </Link>
         </div>
         <div className='home-event-card home-event-card-1'>
         <Link style={{textDecoration:'none'}} to="/events/ncount-training-mohali-2024">
            <div className='home-event-card-time'>
                <p className='home-event-card-date'>09</p>
                <p className='home-event-card-month'>June' 24</p>
            </div>
            <div className='home-event-card-desc-section'>
            <p className='home-event-card-desc'>Mohali nCount review meeting</p>
            </div>
            <p className='home-event-card-desc-text'>ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
            <div className='home-event-card-location-section-1' >
                <div className='home-event-card-location-section-map'>
                    <img src={mapIcon} className='home-event-card-location-section-map-icon'/>
                    <p className='home-event-card-location-section-map-location'>Mohali</p>
                </div>
                <img src={arrowIcon} className='home-event-card-location-section-arrow-icon'/>
            </div>
            </Link>
         </div> */}
            </div>
            </Col>
}
            <Col md="5" style={{paddingRight:35,display:'flex',justifyContent:'center'}}>
            <div>
            <p className='home-event-title-name'>Workshops and Events</p>
            <p className='home-event-title-desc'>Don't Miss Out on Our Dynamic Events and Workshops!</p>
            <Input className='event-subscription-input' placeholder='Your Email ID'/>
            <button className='event-subscription-button'>Subscribe</button>
         </div>
            </Col>
      </Row>
    </div>
  )
}

export default Events