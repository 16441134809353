import React from 'react'
import { Col, Row } from 'reactstrap'
import demoUser from '../../assests/images/demo-user.png';
import likeImg from '../../assests/icons/like.png';
import shareImg from '../../assests/icons/share.png';
function PublicationsList() {
 const items = [{},{},{},{},{}];
  const tags = ['Forest Governance','Bamboo','Forest Rights','Forest Governance','Bamboo','Forest Rights']
  return (
    <div>
    <div style={{paddingTop:20,padding:'20px 15px',borderBottom:'2px dashed #7B3C2E'}}>
        <Row className='pubs-search-row'>
          <p className='publication-list-title'>Our Publications</p>
          <input className='publication-list-input' placeholder='search'/>
        </Row>
    </div>
    <Row>
        <Col md="9" style={{borderRight:'2px dashed #7B3C2E'}}>
        {
            items.map((item)=>{
                return(
                    <div className='pubs-article-card'>
                    <p className='pubs-article-title'>Himachal Pradesh Bioenergy Mission: Initial consultation with the Chief Minister</p>
                    <p className='pubs-article-desc'>
                    ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis, ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis
                    </p>
                      <div className='pubs-article-writer-details'>
                       <div className='pubs-article-user-details'>
                        <img src={demoUser} style={{width:30,marginRight:10}}/>
                        <div>
                        <p style={{marginBottom:0,fontSize:10}}>Full Name</p>
                        <p  style={{marginBottom:0,fontSize:10}}>Designation_Place of work </p>
                        </div>
                       </div>
                       <div className='pubs-article-social-media'>
                        <img src={likeImg} style={{width:20,objectFit:'contain',marginRight:15}}/>
                        <img src={shareImg} style={{width:20,objectFit:'contain',marginRight:15}}/>
                        <p>30 May 2024</p>
                       </div>
                      </div>
                    </div>
                )
            })
        }
        
        </Col>
        <Col md="3">
        {
            tags.map((tag)=>{
                return (
                    <button className='tag-button'>{tag}</button>
                )
            })
        }
        </Col>
    </Row>
    </div>
  )
}

export default PublicationsList