import React, { useState } from 'react'
import Home from './Home'
import Vision from './Vision'
import Team from './Team'
import ContactForm from './ContactForm';

function Main() {
  const [isTeamVisible,setTeamVisible]=useState(false);
  return (
    <>
    <Home/>
    {/* <Team/> */}
    {
      isTeamVisible ?
      <Team/>
      :
      <Vision setTeamVisible={setTeamVisible}/>
    }

    </>
  )
}

export default Main