import React from 'react'
import Header from '../../components/Header2'
import { Col, Row } from 'reactstrap'
import demoEvent from '../../assests/images/event_demo.png'
import demoEventDate from '../../assests/images/demo-event-date.png';
import roundTable from '../../assests/images/round-table.png';
import linePlane from '../../assests/images/line-plane.png'
import { Link } from 'react-router-dom';
import Events from '../home/Events';
function HomeUpdated() {
  return (
    <div>
    <div className='event-landing-page mb-5'>
    <Header activeLink={'4'} background={false} modified={false}/>
    <img src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/events/LE_Event_2024.png'} className="background-video"/>
    </div>
    <Events/>
    {/* <div className='mt-3'>
        <Row style={{width:'100%',padding:0,margin:0}}>
           <img src={linePlane} style={{width:'100%',padding:0,margin:0}}/> 
        </Row>
    </div> */}
    </div>
  )
}

export default HomeUpdated