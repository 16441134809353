import React,{useLayoutEffect, useState} from 'react'
import Header from '../../../../components/Header2'
import { Col, Row } from "reactstrap";
import BlogsCarousel from './BlogsCarousel';
import ProjectCarousel from '../../../home/ProjectCarousel';
import MyCarousel from '../../../home/Carousel';
import { Link } from 'react-router-dom';

function JharFraLaunch() {
    const tags = ['Forest Governance','Jharkhand','Technology']
   
  return (
    <div style={{overflow:'hidden'}}>
    <div className='home-landing-page'>
    <Header activeLink={'1'} background={true} modified={false}/>
    <Row className='home-page-title-row'>
       
        <p className='home-page-title'>JharFRA: Transforming Forest Governance in Jharkhand through Technology</p>
         <p className='home-page-title-desc'>Chief Minister Hemant Soren during the launch of "Abua Bir Abua Dishom Abhiyan" (My Jungle, My Country) in Ranchi, Jharkhand.</p>
      </Row>
      <img  src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jharfra-landing-image.png'} className="background-video"/>
    <div>
    </div>
    </div>
    <div>
        <Row>
            <Col md="1">
            </Col>
            <Col md="8" className='jharfra-text'>
            <p className='jharfra-title'>JharFRA</p>
            <p className='jharfra-desc'>{"On 6 November 2023, Jharkhand Chief Minister Hemant Soren launched the ‘Abua Bir Abua Dishom Abhiyan’ in Ranchi, a campaign focused on granting land ownership (pattas) to forest dwellers under the Scheduled Tribes and Other Traditional Forest Dwellers (Recognition of Forest Rights) Act 2006. This initiative targets the quick processing of both pending applications and new claims in the coming months. The campaign’s goal is to ensure all eligible tribals and forest dwellers receive their land ownership rights. It is expected to benefit 1 lakh individuals and 10,000 villages access to individual and community forest resource rights respectively in Jharkhand."}</p>

<p className='jharfra-desc'>{"The key developments in this collaboration include the launch of our app and website dedicated to the campaign, along with a Standard Operating Procedure (SOP) booklet, a user manual for the JharFRA app, and various other resource materials. These tools were developed to streamline and standardize the process of filing claims under the Forest Rights Act of 2006."}</p>

<p className='jharfra-desc'>{"The government’s knowledge and technology partners include the Foundation of Ecological Security (FES), with its partner organization Phia Foundation in Jharkhand, and the Initiative on the Forest Economy (IoFE) team at the Indian School of Business (ISB)."}</p>

<p className='jharfra-desc'>{"Our team visited Ranchi to assist with integrating technology into the Forest Rights Act (FRA) campaign. We met with key stakeholders from the revenue, forest, and IT departments to align the technological aspects of the campaign."}</p>

<p className='jharfra-desc'>{"Significant discussions took place with the Forest Department, particularly with CCF Research Siddharth Tripathi, DFO Social Forestry Vikas Ujjwal, and the Forest Department’s GIS team. The focus was on exploring collaborative opportunities with ISB. The Forest Department expressed interest in refining a tool they developed for identifying eligible areas for Individual Forest Rights (IFR) claims through satellite imagery. This tool might be integrated into the JharFRA app dashboard if deemed beneficial. Additionally, the department plans to test the app’s functionality on the ground."}</p>

<p className='jharfra-desc'>{"A comprehensive training plan is being developed by the FRA cell. This plan involves a cascading model of training from the state to district to panchayat level, focusing on creating master trainers who will train government officials and Beer Bandhus. A special training for 30 tech master trainers on the JharFRA app is also planned, who will then be responsible for training Beer Bandhus over three months."}</p>

<p className='jharfra-desc'>{"Jharkhand’s ‘Abua Bir Abua Dishom Abhiyan’ emerges as a beacon of transformative forest governance. Fueled by technology and strategic collaborations, the campaign not only streamlines land ownership rights but also promises a positive impact on the lives of tribal and forest communities. The integration of the JharFRA app and comprehensive training programs reflects a forward-thinking approach, marking a significant step towards inclusive and effective forest governance in the region."}</p>
<Link to="/our-work" style={{textDecoration:'none'}}>        
<button style={{marginBottom:10}} className="blog-details-button blog-details-button-mob-1">Back to all work</button>
</Link>
            </Col>
            <Col md="3">
            {
                tags.map((tag)=>{
                    return (
                        <button className='tag-button'>{tag}</button>
                    )
                })
            }
        <br></br>
        <center>
        <Link to="/our-work" style={{textDecoration:'none'}}>
        <button style={{marginBottom:10,marginTop:10}} className="blog-details-button blog-details-button-mob">Back to all work</button>
           </Link> 
        </center>
            </Col> 
            {/* <BlogsCarousel/> */}
        </Row>
    </div>
</div>
  )
}

export default JharFraLaunch