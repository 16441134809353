import React, { useLayoutEffect, useState } from 'react'
import Navbar from './components/Header'
import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home/Main';
import Work from './pages/work/main';
import OdishaHome from './pages/work/odisha/Home'
import Footer from './components/Footer';
import Blog from './pages/work/odisha/blog-page/Blog'
import Library from './pages/library/Library';
import Publications from './pages/library/Publications';
import Stories from './pages/library/Stories';
import About from './pages/about/Main';
import Event from './pages/event/Main';
import RoundTables from './pages/event/RoundTables';
import HP from './pages/work/odisha/HP';
import JharkhandHome from './pages/work/odisha/Jharkhand';
import JharFraLaunch from './pages/work/odisha/blog-page/JharFraLaunch';
import JungleRani from './pages/work/odisha/blog-page/JungleRani';
import NcountTraining from './pages/work/odisha/blog-page/NcountTraining';
import CarbonPilot from './pages/work/odisha/blog-page/CarbonPilot';
import SMI from './pages/library/pubs-list/SMI';
import Hp_Colab from './pages/library/pubs-list/Hp_Colab';
import BiofuelCircle from './pages/library/pubs-list/BiofuelCircle';
import ILE23 from './pages/event/events/ILE23';
import Ncount_Training_Mohali from './pages/event/events/Ncount_Training_Mohali';
import ScrollToTop from './components/ScrollToTop';
import Goa from './pages/work/odisha/Goa';
import Mh from './pages/work/odisha/Mh';
import Andhra from './pages/work/odisha/Andhra';
import ISB_TCS_CARD3 from './pages/work/odisha/blog-page/ISB_TCS_CARD3';
import Women_Led_Fpcs_Card4 from './pages/work/odisha/blog-page/Women_Led_Fpcs_Card4';
import JharFRA_Training_In_Potka from './pages/work/odisha/blog-page/JharFRA_Training_In_Potka';
import ForestBioenergy from './pages/event/roundtables/ForestBioenergy';
import GoaMou from './pages/work/odisha/blog-page/GoaMou';

function App() {
  const [size,setSize] = useState([]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  console.log("Size",)
  return (
    <div>
      {/* <p>{size}</p> */}
        <BrowserRouter>
        <ScrollToTop/>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-work" element={<Work/>}/>
        <Route path="/library" element={<Library/>}/>
        <Route path="/our-work/Odisha" element={<OdishaHome/>} />
        <Route path="/our-work/himachal-pradesh" element={<HP/>} />
        <Route path="/our-work/jharkhand" element={<JharkhandHome/>} />
        <Route path='/our-work/goa' element={<Goa/>}/>
        <Route path='/our-work/maharashtra' element={<Mh/>}/>
        <Route path='/our-work/andhra-pradesh' element={<Andhra/>}/>
        <Route path="/our-work/jharkhand/jharfra-launch" element={<JharFraLaunch/>} />
        <Route path="/our-work/odisha/jungle-rani" element={<JungleRani/>} />
        <Route path="/our-work/himachal-pradesh/ncount-training" element={<NcountTraining/>} />
        <Route path="/our-work/ISB-TCS partnership to build a sustainable forest economy in India" element={<ISB_TCS_CARD3/>}/>
        <Route path="/our-work/Women-led FPCs carry out successful Sal Seed Sale to AAK in Gumla and Ranchi District, Jharkhand" element={<Women_Led_Fpcs_Card4/>}/>
        <Route path="/our-work/JharFRA Training in Potka" element={<JharFRA_Training_In_Potka/>}/>
        <Route path="/our-work/carbon-pilot" element={<CarbonPilot/>} />
        <Route path="/our-work/Odisha/shaping-tomorrow-future" element={<Blog/>} />
        <Route path = "/our-work/Goa/Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa" element={<GoaMou/>}/>
        <Route path = "/library/publications" element={<Publications/>}/>
        <Route path = "/library/bipp-smi" element={<SMI/>}/>
        <Route path = "/library/himachal-pradesh-collaboration" element={<Hp_Colab/>}/>
        <Route path = "library/biofuel-circle" element={<BiofuelCircle/>}/>
        <Route path = "/library/stories" element={<Stories/>}/>
        <Route path='/about-us' element={<About/>}/>
        <Route path='/events' element={<Event/>}/>
        <Route path='/events/international-learning-exchange-2023' element={<ILE23/>}/>
        <Route path='/events/ncount-training-mohali-2024' element={<Ncount_Training_Mohali/>}/>
        <Route path="/round-table/The Forests’ Bioenergy Opportunity: Investments Powering Sustainability" element={<ForestBioenergy/>}/>
        {/* <Route path='/events/round-tables' element={<RoundTables/>}/> */}
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  )
}

export default App