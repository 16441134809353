import React from 'react'
import Header from '../../../components/Header2'
import { Col, Row } from 'reactstrap'
import MyCarousel from '../../home/Carousel'

function Hp_Colab() {
    const tags = ['Objective and Vision ','Innovative Methodology ','Phased Approach ','Anticipated Outcomes ']
  return (
    <div>
        <div className='home-landing-page'>
    <Header activeLink={'2'} background={true} modified={false}/>
    <Row className='home-page-title-row' style={{top:'90%'}}>
       
        <p className='home-page-title'>Collaboration for Himachal Pradesh Scientific Forest Inventory</p>
         {/* <p className='home-page-title-desc'>Chief Minister Hemant Soren during the launch of "Abua Bir Abua Dishom Abhiyan" (My Jungle, My Country) in Ranchi, Jharkhand.</p> */}
      </Row>
      <img  src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/hp_collab.png'} className="background-video"/>
    </div>
    <div style={{background: 'var(--Background, #F9F2EF)'}}>
    <div>
    <Row>
                    <Col md="12">
                    <div className='smi-about-the-project-div'>
                        <p className='smi-about-the-project-div-text'>About the Project</p>
                    </div>
                    </Col>
                </Row>
    </div>
    <div>
            <Row style={{paddingLeft:5}} className='mt-2'>
                <Col md="1">
                </Col>
                <Col md="10">
                <p className='jharfra-desc mt-3'>In the serene and verdant landscapes of Himachal Pradesh, a groundbreaking scientific initiative is taking shape, spearheaded by the esteemed Bharti Institute of Public Policy at the Indian School of Business in collaboration with the Himachal Pradesh Forest Department (HPFD). The project, titled “Himachal Pradesh Seasonal Forest Products Inventory,” embarks on a mission to catalog and understand the diverse array of seasonal forest products in this ecologically rich region using nCount technology. </p>
                {
            tags.map((tag)=>{
                return (
                    <button className='tag-button'>{tag}</button>
                )
            })
    }
            </Col>
                <Col md="1">
                </Col>
                {/* <center><button className='blog-details-button'>Back to Library</button></center> */}
            </Row>
        </div>
        <div className='mt-3'>
            <Row className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                <p className='hp_colab_text'>Objective and Vision</p>
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                At the heart of this collaboration lies a vision of sustainability and prosperity. The primary objective is to conduct a comprehensive inventory of selected forest products, assessing their distribution and abundance with precision. This endeavor is not just about mapping resources; it’s a strategic step toward realizing the potential revenue from the sustainable management and regulation of these natural treasures. In doing so, the project aims to contribute significantly to wealth generation, job creation, and the fortification of rural livelihoods in Himachal Pradesh. 
                </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        <div className='mt-3'>
            <Row className='smi-about-the-project-div hp_colab_div' style={{background: 'var(--Background, #F9F2EF)'}}>
                <Col md="1">
                <p className='hp_colab_text'>Innovative Methodology </p>
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The methodology adopted for this inventory is a blend of traditional ecological knowledge and cutting-edge technology. It involves an extensive collection of high-quality data, harnessing the capabilities of sensors mounted on satellites and drones. This advanced approach is complemented by the practice of ground-truthing, ensuring the accuracy and reliability of the data collected. An integral part of the methodology is the compilation of both scientific and traditional insights into sustainable extraction practices, marrying age-old wisdom with modern science. 
                </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        <div className='mt-3'>
            <Row className='smi-about-the-project-div hp_colab_div'>
                <Col md="1">
                <p className='hp_colab_text'>Phased Approach </p>
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The project is meticulously structured into two distinct phases, each with its unique focus and set of activities. Phase 1, spanning February to December 2024, is dedicated to identifying and modeling the distribution of seasonal forest products. This phase is bifurcated into two tracks: the first deals with species that are gregarious and proximate to human habitation, and the second focuses on more elusive high-altitude species, necessitating extensive fieldwork for data collection. Phase 2, scheduled from January to March 2025, is reserved for synthesizing the collected data and knowledge into a comprehensive report. This report will not only highlight the economic potential of these forest products but also lay out a roadmap for their sustainable management.  
                </p>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        <div className='mt-3'>
            <Row className='smi-about-the-project-div hp_colab_div' style={{background: 'var(--Background, #F9F2EF)'}}>
                <Col md="1">
                <p className='hp_colab_text'>Anticipated Outcomes </p>
                </Col>
                <Col md="10">
                <p className='jharfra-desc hp_colab_left_border'>
                The outputs of this project will include co-produced reports on the inventory of selected seasonal forest products in Himachal Pradesh, complete with high-resolution maps for each species. These maps will be released continuously throughout the year. Additionally, efforts will be made to publicize the findings among relevant stakeholders, and BIPP researchers will prepare papers for publication in high-ranking journals to highlight the collaborative knowledge produced. 
Phase 2, scheduled for January to March 2025, will build on the comprehensive knowledge gained in Phase 1. A detailed report will outline the potential of seasonal forest products in generating wealth, creating jobs, and securing rural livelihoods through scientific resource management. This report will identify high-potential clusters for priority action, suggest institutional mechanisms for tapping business opportunities through community-based enterprises, and recommend ways to integrate monitoring mechanisms into formal forest management systems. A symposium will be organized to gather feedback from experts, and the final report will be refined based on their input. 
This collaborative initiative between HPFD and BIPP represents a significant step forward in leveraging technology and traditional knowledge for sustainable forest management in Himachal Pradesh. 
                </p>
                <center><button className='blog-details-button'>Back to Library</button></center>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
        <div>
            <Row className='mt-5' style={{paddingLeft:5}}>
                <Col md="1">
                </Col>
                <Col md="10">
                <MyCarousel/>
                </Col>
                <Col md="1">
                </Col>
            </Row>
        </div>
    </div>
    </div>
  )
}

export default Hp_Colab