import React from 'react'
import Home from './Home'
import BlogDetails from './BlogDetails'

function Blog() {
  return (
    <>
    <Home/>
    <BlogDetails/>
    </>
  )
}

export default Blog