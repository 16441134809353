import React, { useLayoutEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import eventImg from '../../assests/images/event-img.png';
import { Link } from 'react-router-dom';

function Workshop() {
  const [size,setSize] = useState([]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  console.log("Size",size)
  return (

    <div style={{marginBottom:40,overflow:'hidden',marginTop:20}}>
        <center>
          <Link to="/events">
        <button className='home-page-button home-workshop-button'>
        Workshops and Events
        </button>
          </Link>
        </center>
        {
          size.length>0 && size[0] <768 ?
          <Row>
            <img style={{marginTop:20}} src={eventImg} />
          </Row>
          :
        <Row style={{alignItems:'center'}}>
            <Col md="2" style={{display:'flex',flexDirection:'row'}}>
            <div className='home-workshop-title'>
            <p className='home-workshop-title-year'>2024</p> 
            <p className='home-workshop-title-name'>Learning Exchange</p>
        </div>
        
            </Col>
            <Col md="8">
        <hr className='home-workshop-dotted-line'/>
            </Col>
            <Col md="2">
            <div className='home-workshop-date-section'>
            <p className='home-workshop-date'>1st-3rd October</p>
            </div>
            <Link to="/events/international-learning-exchange-2023">
            <button className='home-page-button home-workshop-button1'>know more</button>
            </Link>
            </Col>
        </Row>
        }
    </div>
  )
}

export default Workshop